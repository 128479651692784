import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Text, Title, Avatar } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { getUserInfo } from '../api/auth';

export default function LoginLandingPage({ navigation }) {
  const linkTo = useLinkTo();

  const handleGoogleLogin = () => {
    // Implement Google login
  };

  const handleAppleLogin = () => {
    // Implement Apple login
  };

  const handleFacebookLogin = () => {
    // Implement Facebook login
  };

  const handleCreateAccount = () => {
    // Navigate to account creation screen
    navigation.navigate('CreateAccount');
  };

  const handleLogin = () => {
    linkTo('/login'); // Link to login screen
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      const userInfo = await getUserInfo();
      console.log("Here's the user info: ", userInfo);
      if (!!userInfo) {
        linkTo('/home');
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <View style={styles.container}>
      <Title style={styles.title}>Welcome to Pinguins Ai!</Title>
      <View style={styles.iconContainer}>
        <Avatar.Image size={128} source={require('../assets/icon.png')} />
      </View>
      <Button mode="contained" onPress={handleCreateAccount} style={styles.button}>
        Create a new account
      </Button>
      <Text style={styles.loginText} onPress={handleLogin}>
        Already have an account? Login
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    flexDirection: 'column'
  },
  iconContainer: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 64,
  },
  iconImage: {
    margin: 'auto',
    margin: 64,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
  button: {
    marginBottom: 16,
  },
  text: {
    textAlign: 'center',
    marginBottom: 16,
  },
  loginText: {
    textAlign: 'center',
    marginTop: 8,
    textDecorationLine: 'underline',
  },
});