import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { StyleSheet, View, Platform, Text } from 'react-native';
import { NavigationContainer, getPathFromState } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// App Screens
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/ProfilePage';
import PingReplyPage from '../pages/PingReplyPage';
import AvatarEditorPage from '../pages/AvatarEditorPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import PingPage from '../pages/PingPage';
import NotificationsPage from '../pages/NotificationsPage';
import SettingsPage from '../pages/SettingsPage';
import NotificationSettings from '../pages/NotificationSettingsPage';
import AccountSettings from '../pages/AccountSettingsPage';
import HelpPage from '../pages/HelpPage';
import AboutPage from '../pages/AboutPage';

import PinguinAppbar from '../components/PinguinAppbar';

const HomeStack = createStackNavigator();

export default function HomeStackNavigator() {
  return (
    <HomeStack.Navigator
      screenOptions={{
        header: (props) => (
          <PinguinAppbar {...props} />
        ),
      }}
    >
      <HomeStack.Screen name="HomeScreen" component={HomePage} />
      <HomeStack.Screen name="Profile" component={ProfilePage} />
      <HomeStack.Screen name="Ping" component={PingPage} />
      <HomeStack.Screen name="Avatar" component={AvatarEditorPage} />
      <HomeStack.Screen name="Settings" component={SettingsPage} options={{ headerShown: false }} />
      <HomeStack.Screen name="NotificationSettings" component={NotificationSettings} options={{ headerShown: false }} />
      <HomeStack.Screen name="AccountSettings" component={AccountSettings} options={{ headerShown: false }} />
      <HomeStack.Screen name="Help" component={HelpPage} options={{ headerShown: false }} />
      <HomeStack.Screen name="About" component={AboutPage} options={{ headerShown: false }} />
    </HomeStack.Navigator>
  );
}

const styles = StyleSheet.create({
  appbarWeb: {
    position: 'relative',
    width: '100%',
    zIndex: 1000,
  },
});
