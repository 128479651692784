import React from 'react';
import { View, Text, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome';
import { AuthContext } from '../contexts/AuthContext';

function SettingsPage({ navigation }) {
  const settings = [
    { label: 'Notifications', screen: 'NotificationSettings', iconName: 'bell' },
    { label: 'Account', screen: 'AccountSettings', iconName: 'user' },
    { label: 'Help', screen: 'Help', iconName: 'question-circle' },
    { label: 'About', screen: 'About', iconName: 'info-circle' },
  ];

  const { signOut } = React.useContext(AuthContext);
  const logoutItem = { label: 'Log Out', iconName: 'sign-out' };
  const handleItemClick = (screen) => {
    navigation.navigate(screen);
  };

  const handleLogoutClick = () => {
    signOut();
    navigation.navigate('LoginLanding');
  };

  const renderSettingsItems = () => {
    return settings.map((item) => (
      <TouchableOpacity
        onPress={() => handleItemClick(item.screen)}
        style={styles.item}
        key={item.label}
      >
        <Icon name={item.iconName} size={20} color="#888" />
        <Text style={styles.itemLabel}>{item.label}</Text>
      </TouchableOpacity>
    ));
  };

  return (
    <View style={styles.container}>
      <ScrollView>
        {renderSettingsItems()}
        <View style={styles.divider} />
        <View style={styles.footer}>
          <TouchableOpacity onPress={() => handleLogoutClick()} style={styles.item}>
            <Icon name={logoutItem.iconName} size={20} color="#888" />
            <Text style={styles.itemLabel}>{logoutItem.label}</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  backButton: {
    fontSize: 18,
    marginRight: 8,
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  itemLabel: {
    marginLeft: 12,
    fontSize: 16,
    color: '#333',
  },
  footer: {
    marginTop: 12,
  },
  divider: {
    height: 1,
    backgroundColor: '#ccc',
    marginHorizontal: 16,
  },
});

export default SettingsPage;
