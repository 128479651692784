import React from 'react';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import AppNavigator from './components/AppNavigator';
import { en, registerTranslation } from 'react-native-paper-dates';
import { PingProvider } from './contexts/PingContext';
import * as SplashScreen from 'expo-splash-screen';
import * as Sentry from 'sentry-expo';

// Prevent native splash screen from autohiding before App component declaration
SplashScreen.preventAutoHideAsync()
  .then((result) => console.log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
  .catch(console.warn); // it's good to explicitly catch and inspect any error

const theme = {
  ...DefaultTheme,
  version: 2,
  colors: {
    primary: 'rgb(41, 236, 254)', // Icy blue header
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(113, 247, 237)',
    onPrimaryContainer: 'rgb(0, 32, 30)',
    secondary: 'rgb(246, 72, 177)', // Pink highlights
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: 'rgb(255, 221, 176)',
    onSecondaryContainer: 'rgb(40, 24, 0)',
    tertiary: 'rgb(105, 95, 0)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(248, 229, 52)',
    onTertiaryContainer: 'rgb(31, 28, 0)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(255, 218, 214)',
    onErrorContainer: 'rgb(65, 0, 2)',
    background: 'rgb(250, 253, 251)',
    onBackground: 'rgb(25, 28, 28)',
    surface: 'rgb(250, 253, 251)',
    onSurface: 'rgb(25, 28, 28)',
    surfaceVariant: 'rgb(218, 229, 226)',
    onSurfaceVariant: 'rgb(63, 73, 71)',
    outline: 'rgb(111, 121, 119)',
    outlineVariant: 'rgb(190, 201, 199)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(45, 49, 48)',
    inverseOnSurface: 'rgb(239, 241, 240)',
    inversePrimary: 'rgb(79, 219, 209)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(238, 246, 243)',
      level2: 'rgb(230, 241, 239)',
      level3: 'rgb(223, 237, 234)',
      level4: 'rgb(220, 235, 233)',
      level5: 'rgb(215, 232, 230)',
    },
    surfaceDisabled: 'rgba(25, 28, 28, 0.12)',
    onSurfaceDisabled: 'rgba(25, 28, 28, 0.38)',
    backdrop: 'rgba(41, 50, 49, 0.4)',
    tooltip: 'rgba(41, 50, 49, 0.7)',
  },
};

Sentry.init({
  dsn: 'https://a3ee75d21490461996dfb50b16d4d04c@o4505599141216256.ingest.sentry.io/4505599149211648',
  enableInExpoDevelopment: false,
  debug: true,
});

export default class App extends React.Component {
  componentDidMount() {
    // Hides native splash screen after 2s
    setTimeout(async () => {
      await SplashScreen.hideAsync();
    }, 1000);

    registerTranslation('en', en);
  }

  render() {
    return (
      <PaperProvider theme={theme}>
        <PingProvider>
          <AppNavigator />
        </PingProvider>
      </PaperProvider>
    );
  }
}
