import { API_BASE_URL } from "./config";

export const loadOpenGraph = async ( url ) => {
  try {
    const response = await fetch(`${API_BASE_URL}/public/opengraph/${encodeURIComponent(url)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    return r.data;
  } catch (error) {
    console.error('Error fetching opengraph:', error);
    throw error;
  }
}