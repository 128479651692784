import React, { useState } from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import { TextInput, Button, HelperText, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { createPing } from '../api/pings';
import { useLinkTo } from '@react-navigation/native';
import { createPinguin } from '../api/pinguins';

const CreatePinguinPage = () => {
  const [pinguinContent, setPinguinContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contentError, setContentError] = useState('');
  const theme = useTheme();
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const postNewPinguin = async () => {
    setIsLoading(true);

    if (pinguinContent.length < 32) {
      setContentError('Pinguin description too short');
      setIsLoading(false);
      return;
    } else if (pinguinContent.length > 2048) {
      setContentError('Pinguin description too long');
      setIsLoading(false);
      return;
    }

    try {
      await createPinguin(pinguinContent);
      linkTo('/mypinguins');
    } catch (error) {
      console.error('Failed to post:', error);
      if (error.type === 'forbidden') {
        setContentError('This content violated our safety standards');
      }
      setIsLoading(false);
    }
  };

  const handleContentChange = (text) => {
    setPinguinContent(text);
  };

  const handleCancelPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      linkTo('/mypinguins');
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.actionBar}>
        <Button style={{ margin: 8 }} onPress={handleCancelPress}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          mode="contained"
          onPress={postNewPinguin}
          textColor="white"
          style={{
            margin: 8,
            backgroundColor: theme.colors.secondary,
            width: 200,
            textColor: 'white',
          }}
        >
          Create Pinguin!
        </Button>
      </View>

      <TextInput
        style={styles.textInput}
        mode="outlined"
        multiline
        numberOfLines={8}
        onChangeText={handleContentChange}
        value={pinguinContent}
        placeholder="Describe your new Pinguin!"
        error={!!contentError}
      />
      {contentError && (
        <HelperText type="error" visible={!!contentError}>
          {contentError}
        </HelperText>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  actionBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  textInput: {
    marginBottom: 16,
    marginHorizontal: 8,
  },
});

export default CreatePinguinPage;
