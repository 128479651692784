import React, { createContext, useState } from 'react';

const PingContext = createContext();

const PingProvider = ({ children }) => {
  const [pingData, setPingData] = useState(null);

  return (
    <PingContext.Provider value={{ pingData, setPingData }}>
      {children}
    </PingContext.Provider>
  );
};

export { PingContext, PingProvider };
