import React, { useEffect, useState, useRef } from 'react';
import {
  StyleSheet,
  View,
  Modal,
  FlatList,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from 'react-native';
import {
  Avatar,
  Card,
  Title,
  Paragraph,
  Button,
  Caption,
  Subheading,
  IconButton,
  Snackbar,
} from 'react-native-paper';
import PingCardContainer from '../containers/PingCardContainer';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { listPingsForUser } from '../api/pings';
import { getUserInfo } from '../api/auth';
import {
  loadUserProfile,
  followUser,
  unfollowUser,
  blockUser,
  unblockUser,
} from '../api/user';
import { reportContent } from '../api/reportContent';

export default function ProfilePage({ route, navigation }) {
  const { username } = route.params;
  const [pings, setPings] = useState([]);
  const [cursor, setCursor] = useState({
    cursor_after: undefined,
    cursor_before: undefined,
  });
  const [page, setPage] = useState(1);
  const [hasMorePings, setHasMorePings] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const height = Dimensions.get('window').height;
  const [userInfo, setUserInfo] = useState(null);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [reportSuccess, setReportSuccess] = useState(false);
  const [blockUserSuccess, setBlockUserSuccess] = useState(false);
  const [unblockUserSuccess, setUnblockUserSuccess] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [showOtherReason, setShowOtherReason] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const reportReasons = [
    { label: 'Bio', value: 'bio' },
    { label: 'Avatar', value: 'avatar' },
    { label: 'Username', value: 'username' },
    { label: 'Other', value: 'other' },
  ];

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevValues = usePrevious({username});

  useEffect(() => {
    const fetchData = async () => {     
      if (loading || hasMorePings === false) return;
      setHasMorePings(true);
      setLoading(true);

      const loggedInUserInfo = await getUserInfo();
      setLoggedInUserInfo(loggedInUserInfo);

      const [userProfile, data] = await Promise.all([
        loadUserProfile(username),
        listPingsForUser({
          username,
          cursor_after: prevValues?.username !== username ? undefined : cursor.cursor_after,
          cursor_before: prevValues?.username !== username ? undefined : cursor.cursor_before,
        }),
      ]);

      if (userProfile?.data?.avatar_url) {
        setAvatarUrl(`${userProfile.data.avatar_url}_128`);
      }

      setUserInfo(userProfile?.data);

      if (data?.pings?.length > 0) {
        setPings((prevPings) => prevValues?.username !== username ? [...data.pings] : [...prevPings, ...data.pings]);
        setCursor({
          cursor_after: !!data.cursor_after ? data.cursor_after : undefined,
          cursor_before: !!data.cursor_before ? data.cursor_before : undefined,
        });
      }

      if (data?.total_count && pings.length >= data.total_count) {
        setHasMorePings(false);
      }

      setLoading(false);
    };

    fetchData();
  }, [username, page, unblockUserSuccess, blockUserSuccess]);

  const handleFollowButton = async () => {
    if (userInfo.is_following) {
      await unfollowUser(username);
      setUserInfo({ ...userInfo, is_following: false });
    } else {
      await followUser(username);
      setUserInfo({ ...userInfo, is_following: true });
    }
  };

  const renderPing = ({ item }) => (
    <PingCardContainer key={item.id} ping={item} />
  );

  const renderFooter = () => {
    if (!loading) return null;

    return (
      <View style={styles.footer}>
        <ActivityIndicator size="small" color="#000000" />
      </View>
    );
  };

  const handleLoadMore = () => {
    if (!loading && hasMorePings === true) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleMenuPress = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const onSnackBarDismiss = () => {
    setReportSuccess(false);
    setUnblockUserSuccess(false);
    setBlockUserSuccess(false);
  };

  const handleReportUser = async () => {
    setIsModalLoading(true);

    try {
      await reportContent(username, 'user', reportReason);
      setReportSuccess(true);
      setIsModalVisible(false);
    } catch (error) {
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleBlockUser = async () => {
    setIsModalLoading(true);

    try {
      await blockUser(username);
      setPings(() => []);
      setBlockUserSuccess(true);
      setIsModalVisible(false);
    } catch (error) {
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleUnblockUser = async () => {
    setIsModalLoading(true);

    try {
      await unblockUser(username);
      setPings(() => []);
      setUnblockUserSuccess(true);
      setIsModalVisible(false);
    } catch (error) {
    } finally {
      setIsModalLoading(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownVisible((prevVisible) => !prevVisible);
  };

  const handleDropdownSelect = (value) => {
    setSelectedValue(value);
    setReportReason(value);
    setShowOtherReason(value === 'other');
    setIsDropdownVisible(false);
  };

  const getSelectedLabel = () => {
    const selectedReason = reportReasons.find(
      (reason) => reason.value === selectedValue
    );
    return selectedReason ? selectedReason.label : 'Select a reason';
  };

  const renderReportModalContent = () => (
    <>
      <View style={styles.modalHeader}>
        <Text style={styles.modalTitle}>User Options</Text>
        <IconButton icon="close" color="#000" onPress={handleCloseModal} />
      </View>
      <View style={styles.modalSeparator} />
      {isModalLoading ? (
        <ActivityIndicator size="large" color="#0000ff" />
      ) : (
        <>
          <View style={styles.menuOption}>
            <TouchableOpacity
              style={styles.menuOption}
              onPress={userInfo?.blocked ? handleUnblockUser : handleBlockUser}
            >
              <MaterialCommunityIcons
                name="account-cancel"
                size={24}
                color="#000"
              />
              <Text style={styles.menuOptionText}>
                {userInfo?.blocked ? 'Unblock' : 'Block'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.modalSeparator} />
          <Text style={styles.modalTitle}>Report User</Text>
          <TouchableWithoutFeedback onPress={toggleDropdown}>
            <View style={styles.pickerContainer}>
              <Text>{getSelectedLabel()}</Text>
              {isDropdownVisible && (
                <View style={styles.dropdownContainer}>
                  {reportReasons.map((reason) => (
                    <TouchableOpacity
                      key={reason.value}
                      style={styles.dropdownItem}
                      onPress={() => handleDropdownSelect(reason.value)}
                    >
                      <Text>{reason.label}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
            </View>
          </TouchableWithoutFeedback>
          {showOtherReason && !isDropdownVisible && (
            <>
              <View style={styles.modalSeparator} />
              <View style={styles.menuOption}>
                <TextInput
                  style={styles.otherReasonInput}
                  placeholder="Enter reason"
                  multiline
                  numberOfLines={4}
                  value={reportReason}
                  onChangeText={setReportReason}
                />
              </View>
            </>
          )}
          {reportReason && !isDropdownVisible && (
            <Button mode="contained" onPress={handleReportUser}>
              Submit
            </Button>
          )}
        </>
      )}
    </>
  );
  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <View style={{ height: height, paddingBottom: 56 }}>
        <FlatList
          contentContainerStyle={styles.content}
          ListHeaderComponent={() => (
            <Card style={styles.headerCard}>
              <View style={styles.menuButtonContainer}>
                {username !== loggedInUserInfo?.username && (
                  <IconButton
                    icon="dots-horizontal"
                    color="#000"
                    onPress={handleMenuPress}
                  />
                )}
              </View>
              {avatarUrl ? (
                <Avatar.Image
                  size={128}
                  source={{ uri: avatarUrl }}
                  style={styles.avatar}
                />
              ) : (
                <Avatar.Image
                  size={128}
                  label={username.slice(0, 2).toUpperCase()}
                  style={styles.avatar}
                />
              )}
              <Card.Content>
                <Title style={styles.title}>
                  {userInfo ? userInfo.display_name : 'Unknown User'} @
                  {username}
                </Title>
                <Caption style={styles.bio}>
                  {userInfo ? userInfo.bio : 'Just another Pinguin!'}
                </Caption>
                <Subheading style={styles.stats}>
                  {userInfo
                    ? `${userInfo.followers_count} followers · ${userInfo.following_count} following · ${userInfo.pings_count} pings`
                    : 'Loading...'}
                </Subheading>
              </Card.Content>
              <Card.Actions>
                {userInfo && (
                  <Button
                    mode="outlined"
                    onPress={handleFollowButton}
                    style={styles.followButton}
                  >
                    {userInfo.is_following ? 'Unfollow' : 'Follow'}
                  </Button>
                )}
              </Card.Actions>
            </Card>
          )}
          ListHeaderComponentStyle={styles.headerCard}
          ListFooterComponent={renderFooter}
          ListEmptyComponent={() => <Paragraph>No pings available</Paragraph>}
          data={pings}
          renderItem={renderPing}
          keyExtractor={(item) => item.id.toString()}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.5}
        />
        <Modal visible={isModalVisible} animationType="slide" transparent>
          <View style={styles.modalContainer}>
            <TouchableOpacity
              style={styles.overlay}
              onPress={handleCloseModal}
            />
            <View style={styles.modalContent}>
              {username !== loggedInUserInfo?.username &&
                renderReportModalContent()}
            </View>
          </View>
        </Modal>
      </View>
      <Snackbar
        visible={reportSuccess}
        onDismiss={onSnackBarDismiss}
        duration={3000}
        label={'User has been reported and will be reviewed.'}
      >
        User has been reported and will be reviewed.
      </Snackbar>
      <Snackbar
        visible={blockUserSuccess}
        onDismiss={onSnackBarDismiss}
        duration={3000}
        label={
          'User has been blocked. You will no longer see their pings and they cannot see yours.'
        }
      >
        User has been blocked. You will no longer see their pings and they
        cannot see yours.
      </Snackbar>
      <Snackbar
        visible={unblockUserSuccess}
        onDismiss={onSnackBarDismiss}
        duration={3000}
        label={
          'User has been blocked. You will no longer see their pings and they cannot see yours.'
        }
      >
        User has been unblocked. You will now see their pings and they will see
        yours.
      </Snackbar>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 16,
  },
  headerCard: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 16,
  },
  avatar: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  title: {
    textAlign: 'center',
  },
  bio: {
    textAlign: 'center',
    marginBottom: 16,
  },
  followButton: {
    marginBottom: 16,
  },
  stats: {
    textAlign: 'center',
    marginBottom: 32,
  },
  pingCard: {
    marginBottom: 16,
    width: '100%',
  },
  footer: {
    marginTop: 10,
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  modalContent: {
    backgroundColor: '#fff',
    height: '70%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 16,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  menuButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  menuOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  menuOptionText: {
    fontSize: 16,
    color: '#C81919',
    fontWeight: 'bold',
    marginLeft: 8,
  },
  modalSeparator: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  otherReasonInput: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 8,
  },
  pickerContainer: {
    borderWidth: 2,
    borderColor: '#ccc',
    borderRadius: 8,
    marginBottom: 8,
    marginTop: 10,
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdownContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 8,
    maxHeight: 200,
    overflowY: 'scroll',
  },
  dropdownItem: {
    padding: 8,
  },
});
