import React, { useState, useEffect, useReducer } from 'react';
import { StyleSheet, View, Text, useWindowDimensions } from 'react-native';
import {
  NavigationContainer,
  getPathFromState,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Linking from 'expo-linking';

import { getToken, logout, login, register, checkSession } from '../api/auth';
import { AuthContext } from '../contexts/AuthContext';

// Auth Screens
import LoginLandingPage from '../pages/LoginLandingPage';
import LoginPage from '../pages/LoginPage';
import CreateAccountPage from '../pages/CreateAccountPage';

// App Screens
import PingReplyPage from '../pages/PingReplyPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ResponsiveNavigator from './ResponsiveNavigator';

const Stack = createStackNavigator();

const prefix = Linking.createURL('/');

/**
 * TODO: Thoroughly reviews these routes
 */
const linking = {
  prefixes: ['http://localhost:19006', 'pinguinai://', prefix],
  config: {
    screens: {
      Main: {
        // Top navigator
        screens: {
          Home: {
            initialRouteName: 'HomeScreen',
            screens: {
              HomeScreen: '/home', // Home screen
              Profile: 'profile/:username',
              Ping: 'pings/:ping_id',
              Avatar: 'avatar',
            },
          },
          Notifications: {
            screens: {
              NotificationsPage: 'notifications',
            },
          },
          'My Pinguins': {
            screens: {
              MyPinguinsPage: 'mypinguins',
              CreatePinguinPage: 'newpinguin',
            },
          },
          Settings: {
            initialRouteName: 'SettingsPage',
            screens: {
              SettingsPage: '/settings',
              NotificationSettings: '/settings/notifications',
              AccountSettings: '/settings/account',
              Help: '/help',
              About: '/about',
            },
          },
        },
      },
      LoginLanding: 'landing',
      CreateAccount: 'create-account',
      Login: 'login',
      CreatePing: 'newping',
      ForgotPassword: 'forgot_password',
      ResetPassword: 'reset_password/:token',
    },
  },
};

function getLinkerPathForState(state) {
  if (state == undefined) {
    return '/home';
  }
  if (state.routes == undefined) {
    return '';
  }

  let lastItem = state.routes[state.routes.length - 1];
  if (lastItem == undefined) {
    return '';
  }

  if (lastItem.hasOwnProperty('path') && lastItem.path != undefined) {
    return lastItem.path;
  }
  if (lastItem.hasOwnProperty('state') && lastItem.state != undefined) {
    return getLinkerPathForState(lastItem.state);
  }
  if (lastItem.hasOwnProperty('params') && lastItem.params != undefined) {
    return lastItem.params.path;
  }
}

export default function AppNavigator() {
  const [currentPath, setCurrentPath] = useState();
  const navigationRef = React.useRef();

  const onStateChange = (state) => {
    if (state) {
      const path = getPathFromState(state, linking.config);
      setCurrentPath(path);
    }
  };

  const getActiveRouteName = (state) => {
    if (!state || !state.routes) {
      return null;
    }
    const route = state.routes[state.index];
    if (route.state) {
      // Nested navigators
      return getActiveRouteName(route.state);
    }
    return route.name;
  };

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        userToken = await getToken();
      } catch (e) {
        // Restoring token failed
      } finally {
        // After restoring token, we may need to validate it in production apps

        // This will switch to the App screen or Auth screen and this loading
        // screen will be unmounted and thrown away.
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      }
    };

    const checkUserSession = async () => {
      try {
        const resp = await checkSession();
        if (resp.error && error.code === 'invalid_token') {
          authContext.signOut();
        }
        const userToken = await getToken();
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      } catch (e) {
        authContext.signOut();
      } finally {
        bootstrapAsync();
      }
    };

    // Check user session before anything else
    checkUserSession();
  }, []);

  useEffect(() => {
    if (navigationRef.current != undefined) {
      navigationRef.current.addListener('state', (e) => {
        console.log('Wow its a fresh new state:', e.data.state);
      });
      let curPath = getLinkerPathForState(navigationRef.current.getState());
      setCurrentPath(curPath);
    }
  });

  const authContext = React.useMemo(
    () => ({
      signIn: async (username, password) => {
        try {
          let token = await login(username, password);
          dispatch({ type: 'SIGN_IN', token });
          return null; // No error
        } catch (error) {
          console.error('An error occurred during sign in', error);
          return error;
        }
      },
      signOut: async () => {
        await logout();
        dispatch({ type: 'SIGN_OUT' });
      },
      signUp: async (data) => {
        try {
          let token = await register(data);
          dispatch({ type: 'SIGN_IN', token });
          return null;
        } catch (error) {
          console.error('An error occurred during sign up', error);
          return error;
        }
      },
    }),
    []
  );

  if (state.isLoading) {
    // We haven't finished checking for the token yet
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  }

  // TODO: Break this component into Navigator.native.js and Navigator.js
  // Only include the Tab bar in Navigator.native.js, and use only a drawer navigator for web
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onStateChange={onStateChange}
    >
      <AuthContext.Provider value={authContext}>
        <View style={styles.rootContainer}>
          <View style={styles.appContainer}>
            <Stack.Navigator>
              {state.userToken === null ? (
                <>
                  <Stack.Screen
                    name="LoginLanding"
                    component={LoginLandingPage}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="Login"
                    component={LoginPage}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="CreateAccount"
                    component={CreateAccountPage}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="ForgotPassword"
                    component={ForgotPasswordPage}
                    options={{ headerShown: false }}
                  />
                </>
              ) : (
                <>
                  <Stack.Screen
                    name="Main"
                    component={ResponsiveNavigator}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="ResetPassword"
                    component={ResetPasswordPage}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    name="CreatePing"
                    component={PingReplyPage}
                    options={{ headerShown: false }}
                  />
                </>
              )}
            </Stack.Navigator>
          </View>
        </View>
      </AuthContext.Provider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  appbarWeb: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  rootContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'row',
  },
  appContainer: {
    height: '100%',
    flex: 1,
    margin: 'auto',
    flexDirection: 'row',
  },
});
