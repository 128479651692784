import React, { useEffect, useState } from 'react';
import { FlatList, View, Text, ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import { useDebouncedCallback } from 'use-debounce';
import { Avatar, Card } from 'react-native-paper';
import { searchUsersByPrefix } from '../api/search';

const UserSearchList = ({ prefix, onSelect }) => {
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([]);
    const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(false);

    const fetchUsers = useDebouncedCallback(async () => {
        console.log("Fetching users with prefix", prefix);
        if (!prefix) {
            setUsers([]);
            return;
        }
        setError(false);
        if (prefix.length === 1) {
          setIsLoadingFirstTime(true);
        }
        try {
            let users = await searchUsersByPrefix(prefix);
            setUsers(users);
        } catch (err) {
            setError(true);
        } finally {
            setIsLoadingFirstTime(false);
        }
    }, 300);

    useEffect(() => {
        fetchUsers();
    }, [prefix, fetchUsers]);

    if (error) {
        return (
            <View style={styles.errorContainer}>
                <Text style={styles.errorText}>Something went wrong!</Text>
            </View>
        );
    }

    if (isLoadingFirstTime) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" />
            </View>
        );
    }

    if (!users || users.length === 0) {
        return (
            <View style={styles.emptyContainer}>
                <Text style={styles.emptyText}>No results found</Text>
            </View>
        );
    }

    const renderItem = ({ item }) => (
        <TouchableOpacity onPress={() => onSelect(item.username)}>
            <Card style={styles.userCard}>
                <Card.Title
                    title={
                        <View>
                            <Text style={styles.displayName}>{item.display_name}</Text>
                            <Text style={styles.username}>{` @${item.username}`}</Text>
                        </View>
                    }
                    left={() => <Avatar.Image size={40} source={item.avatar_url ? { uri: `${item.avatar_url}_64` } : null }/>}
                />
            </Card>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>
            <FlatList
                data={users}
                renderItem={renderItem}
                keyExtractor={item => item.username}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    // Define your styles here
    container: {
        // Styles for the container
    },
    userCard: {
        // Styles for the user card
    },
    displayName: {
        // Styles for the display name
    },
    username: {
        color: '#777',
    },
    // Add more styles as needed
});

export default UserSearchList;
