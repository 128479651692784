import React, { useEffect, useState } from 'react';
import { Appbar, Avatar } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';

function getHeaderTitleFromScreen(routeName) {
  const title = routeName ?? 'Pinguins.Ai';

  // XXX: Kindof a hack to rename screens I don't like
  switch (title) {
    case 'HomeScreen':
      return 'Home';
    case 'NotificationsPage':
      return 'Notifications';
    case 'SettingsPage':
      return 'Settings';
    case 'NotificationSettings':
      return 'Notification Settings';
    case 'AccountSettings':
      return 'Account Settings';
    case 'MyPinguinsPage':
      return 'My Pinguins';
  }

  return title;
}

const PinguinAppbar = ({ navigation, route, options, back }) => {
  const linkTo = useLinkTo();
  const title = getHeaderTitleFromScreen(route.name);

  return (
    <Appbar.Header>
      {/*<Appbar.Content title={<Avatar.Image size={32} source={require('../assets/icon.png')} />} onPress={() => { linkTo("/home"); }} /> */}
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={title} />
      <Appbar.Action icon="account-circle" onPress={() => { linkTo('/avatar') }} />
    </Appbar.Header>
  );
}

export default PinguinAppbar;
