import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, ScrollView } from 'react-native';
import { ActivityIndicator, Card, Avatar } from 'react-native-paper';
import { listNotifications } from '../api/notifications';
import { useLinkTo, Link } from '@react-navigation/native';

function linkToProfile(username) {
  return {
    screen: 'Main',
    params: {
      screen: 'Home',
      params: {
        screen: 'Profile',
        params: {
          username,
        },
      },
    },
  };
}

const FollowerPingCard = ({ notification }) => {
  return (
    <Card style={styles.notificationCard}>
      <Card.Title
        title={
          <>
            {!!notification.follower.username ? (
              <View>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Link to={linkToProfile(notification.follower.username)}>
                      {notification.follower.display_name}
                    </Link>
                  </Text>
                  <Text> followed you</Text>
                </Text>
              </View>
            ) : (
              <View>
                <Text style={{ fontWeight: 'bold' }}>[deleted]</Text>
                <Text> followed you</Text>
              </View>
            )}
          </>
        }
        left={() => <Avatar.Icon size={32} icon="account" color="#FF48B1" />}
      />
    </Card>
  );
};

const LikePingCard = ({ notification }) => {
  return (
    <Card style={styles.notificationCard}>
      <Card.Title
        title={
          <>
            {!!notification.liker.username ? (
              <View>
                <Link to={linkToProfile(notification.liker.username)}>
                  <Avatar.Image
                    size={32}
                    source={{ uri: `${notification.liker.avatar_url}_32` }}
                  />
                </Link>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Link to={linkToProfile(notification.liker.username)}>
                      {notification.liker.display_name}
                    </Link>
                  </Text>
                  <Text> liked your ping</Text>
                </Text>
              </View>
            ) : (
              <View>
                <Text style={styles.deletedUsername}>[deleted]</Text>
                <Text> liked your ping</Text>
              </View>
            )}
          </>
        }
        subtitle={notification.like_ping.content}
        left={() => <Avatar.Icon size={32} icon="heart" color="#FF48B1" />}
      />
    </Card>
  );
};

const ReplyPingCard = ({ notification }) => {
  const linkTo = useLinkTo();

  return (
    <>
      {!!notification.reply_ping.username ? (
        <Card
          style={styles.notificationCard}
          onPress={() => linkTo(`/pings/${notification.reply_ping.id}`)}
        >
          <Card.Title
            title={
              <View>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Link to={linkToProfile(notification.reply_ping.username)}>
                      {notification.reply_ping.display_name}
                    </Link>
                  </Text>
                  <Text> replied to your ping</Text>
                </Text>
              </View>
            }
            subtitle={notification.reply_ping.content}
            left={() => (
              <Link to={linkToProfile(notification.reply_ping.username)}>
                <Avatar.Image
                  size={32}
                  source={{ uri: `${notification.reply_ping.avatar_url}_32` }}
                />
              </Link>
            )}
          />
        </Card>
      ) : (
        <Card style={styles.notificationCard}>
          <Card.Title
            title={
              <View>
                <Text style={styles.deletedUsername}>[deleted]</Text>
                <Text> replied to your ping</Text>
              </View>
            }
            subtitle={notification.reply_ping.content}
            left={() => <Avatar.Icon size={32} color="#FF48B1" />}
          />
        </Card>
      )}
    </>
  );
};

const QuotePingCard = ({ notification }) => {
  const linkTo = useLinkTo();

  return (
    <>
      {!!notification.quote_ping.username ? (
        <Card
          style={styles.notificationCard}
          onPress={() => linkTo(`/pings/${notification.quote_ping.id}`)}
        >
          <Card.Title
            title={
              <View>
                <Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Link to={linkToProfile(notification.quote_ping.username)}>
                      {notification.quote_ping.display_name}
                    </Link>
                  </Text>
                  <Text> quoted your ping</Text>
                </Text>
              </View>
            }
            subtitle={notification.quote_ping.content}
            left={() => (
              <Link to={linkToProfile(notification.quote_ping.username)}>
                <Avatar.Image
                  size={32}
                  source={{ uri: `${notification.quote_ping.avatar_url}_32` }}
                />
              </Link>
            )}
          />
        </Card>
      ) : (
        <Card style={styles.notificationCard}>
          <Card.Title
            title={
              <View>
                <Text style={styles.deletedUsername}>[deleted]</Text>
                <Text> quoted your ping</Text>
              </View>
            }
            subtitle={notification.quote_ping.content}
            left={() => <Avatar.Icon size={32} color="#FF48B1" />}
          />
        </Card>
      )}
    </>
  );
};

const NotificationCard = ({ notification }) => {
  if (notification.type === 'like') {
    return <LikePingCard notification={notification} />;
  }
  if (notification.type === 'follow') {
    return <FollowerPingCard notification={notification} />;
  }
  if (notification.type === 'reply') {
    return <ReplyPingCard notification={notification} />;
  }
  if (notification.type === 'quote') {
    return <QuotePingCard notification={notification} />;
  }

  return (
    <Card style={styles.notificationCard}>
      <Text>{notification.type}</Text>
    </Card>
  );
};

export default function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRecentNotifications = async () => {
      console.log('fetching recent pings');
      setLoading(true);

      try {
        let data = await listNotifications();
        setNotifications(data);
      } catch (error) {
        console.error('Failed to list notifications', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentNotifications();
  }, []);

  return (
    <>
      <View style={styles.container}>
        {loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator animating={true} />
          </View>
        )}
        <ScrollView contentContainerStyle={styles.content}>
          {notifications?.map((notification, index) => (
            <NotificationCard key={index} notification={notification} />
          ))}
        </ScrollView>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 8,
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  pingInput: {
    marginBottom: 16,
  },
  postButton: {
    marginBottom: 16,
  },
  pingCard: {
    marginBottom: 16,
  },
  usernameLink: {
    textDecorationLine: 'none',
    color: 'inherit',
  },
  deletedUsername: {
    color: '#919090',
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
  fabWeb: {
    position: 'fixed',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
  },
  tabBar: {
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
  },
  notificationCard: {
    padding: 8,
    marginBottom: 8,
  },
});
