import { getToken } from './auth';
import { API_URL } from './config';

export const createPinguin = async (description) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const jsonBody = JSON.stringify({ description: description });
  const response = await fetch(`${API_URL}/pinguins`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: jsonBody,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  } else {
    return data;
  }
};
