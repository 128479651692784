import React from 'react';
import { Linking } from 'react-native';
import { List, TouchableRipple } from 'react-native-paper';

export default function ListItemLink({ title, description, url }) {
  return (
    <TouchableRipple onPress={() => Linking.openURL(url)}>
      <List.Item
        title={title}
        description={description}
        descriptionNumberOfLines={3}
        left={props => <List.Icon {...props} icon="file-document-outline" />}
        right={props => <List.Icon {...props} icon="open-in-new" />}
      />
    </TouchableRipple>
  );
}