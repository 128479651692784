import React, { useState, useContext } from 'react';
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';
import {
  TextInput,
  Button,
  Card,
  Paragraph,
  Avatar,
  HelperText,
  useTheme,
} from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { createPing } from '../api/pings';
import * as Localization from 'expo-localization';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useLinkTo } from '@react-navigation/native';
import UserSearchList from '../components/UserSearchList';
import { PingContext } from '../contexts/PingContext';

const PingReplyPage = () => {
  const [replyContent, setReplyContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contentError, setContentError] = useState('');
  const theme = useTheme();
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const { setPingData } = useContext(PingContext);

  const route = useRoute();
  const pingInfo = {
    ping: undefined,
    type: undefined,
  };

  if (route.params?.parent) {
    pingInfo.ping = JSON.parse(decodeURIComponent(route.params?.parent));
    pingInfo.type = 'parent';
  } else if (route.params?.quoted_ping) {
    pingInfo.ping = JSON.parse(decodeURIComponent(route.params?.quoted_ping));
    pingInfo.type = 'quoted_ping';
  } else {
    pingInfo.type = 'new_ping';
  }

  const parsedDate = pingInfo.ping && parseISO(pingInfo.ping.inserted_at);
  const locales = Localization.getCalendars();
  const userTimezone = locales[0].timeZone;
  const localDate = pingInfo.ping && utcToZonedTime(parsedDate, userTimezone);
  const relativeDate =
    pingInfo.ping && formatDistanceToNow(localDate, { addSuffix: true });

  const [showUserSearch, setShowUserSearch] = useState(false);
  const [searchPrefix, setSearchPrefix] = useState('');
  const inputRef = React.useRef(null);

  const handleContentChange = async (content) => {
    if (content.length < 250) {
      setReplyContent(content);
      // Check if the last character is @ and set the search prefix and showUserSearch accordingly
      const lastCharacter = content.charAt(content.length - 1);
      if (lastCharacter === '@') {
        setShowUserSearch(true);
        setSearchPrefix('');
      } else if ([' ', '.'].includes(lastCharacter) || !content.includes('@')) {
        setShowUserSearch(false);
      } else if (showUserSearch) {
        const atPosition = content.lastIndexOf('@');
        const prefix = content.substring(atPosition + 1);
        console.log('Updating search prefix: ', prefix);
        setSearchPrefix(prefix);
      }
    }
  };

  const handleUserClick = (username) => {
    const atPosition = replyContent.lastIndexOf('@');
    const newText = `${replyContent.substring(0, atPosition)}@${username} `;
    setReplyContent(newText);
    setShowUserSearch(false);
    inputRef.current.focus();
  };

  const postReply = async () => {
    setIsLoading(true);

    if (replyContent.length < 3 || replyContent.length > 250) {
      console.error('Ping too long');
      return;
    }

    try {
      let ping = await createPing(replyContent, pingInfo);
      setPingData(ping);
      linkTo('/home');
    } catch (error) {
      console.error('Failed to post:', error);
      if (error.type === "forbidden") {
        setContentError('This content violated our safety standards');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      linkTo('/home');
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.actionBar}>
        <Button style={{ margin: 8 }} onPress={handleCancelPress}>Cancel</Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          mode="contained"
          onPress={postReply}
          style={{ margin: 8, backgroundColor: theme.colors.secondary, width: 100 }}
        >
          {pingInfo.type === 'parent' ? 'Reply' : 'Ping'}
        </Button>
      </View>
      <>
        {pingInfo.type === 'parent' && (
          <Card style={styles.pingCard}>
            <Card.Title
              title={
                <>
                  <Text style={styles.displayName}>
                    {pingInfo.ping.display_name}
                  </Text>
                  <Text
                    style={styles.username}
                  >{` @${pingInfo.ping.username}`}</Text>
                </>
              }
              subtitle={<Text>{relativeDate}</Text>}
              left={() => (
                <Avatar.Image
                  size={40}
                  source={
                    pingInfo.ping.avatar_url
                      ? { uri: `${pingInfo.ping.avatar_url}_64` }
                      : null
                  }
                />
              )}
            />
            <Card.Content>
              <Paragraph>{pingInfo.ping.content}</Paragraph>
            </Card.Content>
          </Card>
        )}
      </>
      {pingInfo.type === 'quoted_ping' && (
        <Card style={styles.pingCard}>
          <Card.Title
            title={
              <>
                <Text style={styles.displayName}>
                  {pingInfo.ping.display_name}
                </Text>
                <Text
                  style={styles.username}
                >{` @${pingInfo.ping.username}`}</Text>
              </>
            }
            subtitle={<Text>{relativeDate}</Text>}
            left={() => (
              <Avatar.Image
                size={40}
                source={
                  pingInfo.ping.avatar_url
                    ? { uri: `${pingInfo.ping.avatar_url}_64` }
                    : null
                }
              />
            )}
          />
          <Card.Content>
            <Paragraph>{pingInfo.ping.content}</Paragraph>
          </Card.Content>
        </Card>
      )}

      <TextInput
        ref={inputRef}
        style={styles.textInput}
        mode="outlined"
        multiline
        numberOfLines={4}
        onChangeText={handleContentChange}
        value={replyContent}
        placeholder={
          pingInfo.type !== 'new_ping'
            ? 'Write your reply...'
            : "What's on your mind?"
        }
        error={!!contentError}
      />
      {contentError ? <HelperText type="error" visible={contentError}>{contentError}</HelperText> : null}
      {showUserSearch && (
        <UserSearchList prefix={searchPrefix} onSelect={handleUserClick} />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  actionBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  pingCard: {
    marginBottom: 16,
  },
  textInput: {
    marginBottom: 16,
    marginHorizontal: 8,
  },
  displayName: {},
  username: {
    color: '#777',
  },
});

export default PingReplyPage;
