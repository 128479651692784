import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, TextInput, Title, Snackbar, HelperText } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { resetPassword } from '../api/auth';  // replace this with the appropriate import for your API
import validator from 'validator';

export default function ResetPasswordPage({ route }) {
  const { token } = route.params;
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const linkTo = useLinkTo();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[-_!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return password.length >= minLength && (hasUpperCase || hasDigit || hasSpecialChar);
  };

  const validateInputs = () => {
    const isPasswordValid = validatePassword(password);

    setPasswordError('');
    setPasswordConfirmationError('');

    let valid = true;

    if (!isPasswordValid) {
      setPasswordError('Please select a more complex password, including at least one uppercase, number or special character.');
      valid = false;
    }

    if (password !== passwordConfirmation) {
      setPasswordConfirmationError('The passwords do not match.');
      valid = false;
    }

    return valid;
  };

  const handleResetPassword = async () => {
    if (!validateInputs()) {
      console.log('Validation error');
      return;
    }

    setLoading(true);

    try {
      const response = await resetPassword({
        token,
        password,
      });

      if (response.ok) {
        setSnackbarMessage('Password reset successfully!');
        setSnackbarVisible(true);
        linkTo('/login');
      } else {
        const errorMessage = await response.text();
        setSnackbarMessage(errorMessage);
        setSnackbarVisible(true);
        console.log('Error resetting password');
      }
    } catch (error) {
      // TODO: Handle expired tokens more gracefully
      setSnackbarMessage('Something went wrong. Please try again.');
      setSnackbarVisible(true);
      console.log('Error resetting password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <Title style={styles.title}>Reset Password</Title>
      <TextInput
        label="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        error={passwordError}
        style={styles.input}
      />
      <HelperText type="error" visible={passwordError}>{passwordError}</HelperText>
      <TextInput
        label="Confirm Password"
        value={passwordConfirmation}
        onChangeText={setPasswordConfirmation}
        secureTextEntry
        error={passwordConfirmationError}
        style={styles.input}
      />
      <HelperText type="error" visible={passwordConfirmationError}>{passwordConfirmationError}</HelperText>
      <Button
        mode="contained"
        onPress={handleResetPassword}
        style={styles.button}
        loading={loading}
        disabled={loading}
      >
        Reset Password
      </Button>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={Snackbar.DURATION_SHORT}
      >
        {snackbarMessage}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
});