import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from './config';

export const searchUsersByPrefix = async (prefix) => {
  try {
    const token = await AsyncStorage.getItem('token');

    // This route should return 200 and an empty list if no prefix matches
    const response = await fetch(`${API_URL}/search/user/${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data.users;  
    }
    throw new Error("Error loading users");
  } catch (error) {
    console.error('Error searching for users:', error);
  }
};