import React, { PureComponent } from 'react';
import PingCard from '../components/PingCard';
import { likePing, unLikePing } from '../api/pings';

class PingCardContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLiked: props.ping.is_liked_by_user,
    };
  }

  handleLikePress = async () => {
    try {
      const { ping } = this.props;
      const { isLiked } = this.state;

      if (isLiked) {
        await unLikePing(ping.id);
      } else {
        await likePing(ping.id);
      }
      this.setState({ isLiked: !isLiked });
    } catch (error) {
      console.error("Error liking the ping: ", error);
    }
  };

  render() {
    const { ping } = this.props;
    const { isLiked } = this.state;

    return (
      <PingCard ping={ping} onLikePress={this.handleLikePress} isLiked={isLiked} />
    );
  }
}

export default PingCardContainer;
