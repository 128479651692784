import React, { useState } from 'react';
import { StyleSheet, ScrollView, Text, TouchableOpacity, SafeAreaView, KeyboardAvoidingView, Platform } from 'react-native';
import { Button, TextInput, Title, HelperText, Snackbar } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { AuthContext } from '../contexts/AuthContext';
import * as Sentry from 'sentry-expo';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [snackbar, setSnackbar] = useState({ "visible": false, "message": '' });

  const [loading, setLoading] = useState(false);
  const linkTo = useLinkTo();
  const { signIn } = React.useContext(AuthContext);

  const handleLogin = async () => {
    setUsernameError('');
    setPasswordError('');

    if (!username) {
      setUsernameError("Please provide a valid username.");
    }

    if (!password) {
      setPasswordError("Please provide a valid password.");
    }

    if (!username || !password) {
      return;
    }

    setLoading(true);

    try {
      const error = await signIn(username, password);

      if (error) {
        if (error.type === 'auth') {
          setUsernameError(error.message);
          setPasswordError(error.message);
        } else {
          // Show snackbar with error.message
          setSnackbar({ visible: true, message: `Unexpected Error: ${error.message}` });
        }
      }

    } catch (error) {
      console.log('Unexpected error logging in:', error);
      if (Platform.OS != 'web') {
        Sentry.Native.captureException(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    linkTo('/landing');
  };

  const handleResetPassword = () => {
    linkTo('/forgot_password');
  };

  return (
    <SafeAreaView style={styles.safeAreaContainer}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.safeAreaContainer}
      >
        <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} keyboardShouldPersistTaps="handled">
          <Title style={styles.title}>Login</Title>
          <TextInput
            label="Username"
            value={username}
            onChangeText={setUsername}
            style={styles.input}
            autoCapitalize='none'
            error={usernameError}
          />
          <TextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            style={styles.input}
            error={passwordError}
          />
          <HelperText type="error" visible={passwordError}>{passwordError}</HelperText>
          <Button mode="contained" onPress={handleLogin} style={styles.button} disabled={loading} loading={loading}>
            Login
          </Button>
          <Button mode="outlined" onPress={handleCancel} style={styles.button}>
            Cancel
          </Button>
          <TouchableOpacity onPress={handleResetPassword}>
            <Text style={styles.linkText}>Forgot Password?</Text>
          </TouchableOpacity>
          <Snackbar
            visible={snackbar.visible}
            onDismiss={() => setSnackbar({ visible: false, message: "" })}
            duration={Snackbar.DURATION_SHORT}
          >
            {snackbar.message}
          </Snackbar>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  linkText: {
    textAlign: 'center',
    marginTop: 16,
  }
});
