import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import NotificationsPage from '../pages/NotificationsPage';
import PinguinAppbar from '../components/PinguinAppbar';

const Stack = createStackNavigator();

/**
 * This component is a little silly - in order to make the headers work
 * nicely on both web and mobile, its easiest to just wrap this in a navigator!
 */
export default function NotificationsStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => (
          <PinguinAppbar {...props} />
        ),
      }}
    >
      <Stack.Screen name="NotificationsPage" component={NotificationsPage} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  appbarWeb: {
    position: 'relative',
    width: '100%',
    zIndex: 1000,
  },
});
