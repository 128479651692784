import React from 'react';
import { useWindowDimensions, View, StyleSheet, TouchableOpacity, SafeAreaView } from 'react-native';
import { Drawer, Avatar, Appbar, Text } from 'react-native-paper';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { getHeaderTitle } from '@react-navigation/elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import HomeStackNavigator from './HomeNavigator';
import MyPinguinsNavigator from './MyPinguinsNavigator';
import SettingsStackNavigator from './SettingsNavigator';
import NotificationsStackNavigator from './NotificationsNavigator';
import DrawerButton from './DrawerButton';
import Icon from "../assets/icon.svg";
import IconFill from "../assets/icon-fill.svg";

function CustomNavigationBar({ navigation, route, options, back }) {
  const title = getHeaderTitle(options, route.name);
  console.log("This is back: ", back);

  return (
    <Appbar.Header>
      <Appbar.Content title={route.name} />
    </Appbar.Header>
  );
}

const Tab = createMaterialBottomTabNavigator();
function TabNavigator() {
  return (
    <Tab.Navigator
      barStyle={{ backgroundColor: 'rgb(41, 236, 254)' }}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'Home') {
            iconName = focused ? 'home' : 'home-outline';
          } else if (route.name === 'My Pinguins') {
            if (focused) {
              return <IconFill width={26} height={26} size={26} color={color} />;
            } else {
              return <Icon width={26} height={26} size={26} color={color} />;
            }
          } else if (route.name === 'Notifications') {
            iconName = focused ? 'bell' : 'bell-outline';
          } else if (route.name === 'Settings') {
            iconName = focused ? 'cog' : 'cog-outline';
          }

          return <MaterialCommunityIcons name={iconName} color={color} size={26} />;
        },
      })}
    >
      <Tab.Screen
        name="Home"
        component={HomeStackNavigator}
      />
      <Tab.Screen
        name="My Pinguins"
        component={MyPinguinsNavigator}
      />
      <Tab.Screen
        name="Notifications"
        component={NotificationsStackNavigator}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsStackNavigator}
      />
    </Tab.Navigator>
  )
}

const DrawerNav = createDrawerNavigator();
function DrawerContent({ navigation }) {
  const navState = navigation.getState();

  return (
    <SafeAreaView>
      <View style={styles.drawerHeader}>
        <TouchableOpacity style={styles.drawerHeaderTouchable} onPress={() => navigation.navigate('Home')}>
          <Avatar.Image style={styles.appIcon} size={64} source={require('../assets/icon.png')} />
          <Text style={styles.headerText}>Pinguins Ai</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Drawer.Item
          label="Home"
          icon={navState.index == 0 ? 'home' : 'home-outline'}
          active={navState.index == 0}
          theme={{ isV3: true }}
          onPress={() => navigation.navigate('Home')}
        />
        <Drawer.Item
          label="My Pinguins"
          icon={() => (navState.index == 1 ? <IconFill width={26} height={26} size={26} /> : <Icon width={26} height={26} size={26} />)}
          active={navState.index == 1}
          theme={{ isV3: true }}
          onPress={() => navigation.navigate('My Pinguins')}
        />
        <Drawer.Item
          label="Notifications"
          icon={navState.index == 2 ? 'bell' : 'bell-outline'}
          theme={{ isV3: true }}
          active={navState.index == 2}
          onPress={() => navigation.navigate('Notifications')}
        />
        <Drawer.Item
          label="Settings"
          icon={navState.index == 3 ? 'cog' : 'cog-outline'}
          theme={{ isV3: true }}
          active={navState.index == 3}
          onPress={() => navigation.navigate('Settings')}
        />
        <DrawerButton
          onPress={() => navigation.navigate('CreatePing')}
        />
      </View>
    </SafeAreaView>
  );
}


function DrawerNavigator() {
  return (
    <DrawerNav.Navigator initialRouteName="home"
      screenOptions={{
        drawerType: 'permanent',
        header: (props) => <CustomNavigationBar {...props} />
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <DrawerNav.Screen
        name="Home"
        component={HomeStackNavigator}
        options={{ headerShown: false }}
      />
      <DrawerNav.Screen
        name="My Pinguins"
        component={MyPinguinsNavigator}
        options={{ headerShown: false }}
      />
      <DrawerNav.Screen
        name="Notifications"
        component={NotificationsStackNavigator}
        options={{ headerShown: false }}
      />
      <DrawerNav.Screen
        name="Settings"
        component={SettingsStackNavigator}
        options={{ headerShown: false }}
      />
    </DrawerNav.Navigator>
  );
}

export default function ResponsiveNavigator() {
  const windowWidth = useWindowDimensions().width;

  if (windowWidth >= 850) {
    return <DrawerNavigator />;
  } else {
    return <TabNavigator />;
  }
}

const styles = StyleSheet.create({
  appIcon: {
    margin: 12,
    marginBottom: 16
  },
  drawerHeader: {
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    height: 92,
  },
  drawerHeaderTouchable: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    height: 92,
  },
  headerText: {
    fontSize: 24,
  }
});
