// src/api/pings.js
import queryString from 'query-string';
import { API_URL } from './config';
import { getToken } from './auth';

export const createPing = async (pingText, pingInfo) => {
  if (pingText.length <= 3) {
    return;
  }

  const token = await getToken();
  const pingData = {
    content: pingText,
    parent_ping_id: pingInfo.type === 'parent' ? pingInfo.ping.id : undefined,
    quoted_ping_id: pingInfo.type === 'quoted_ping' ? pingInfo.ping.id : undefined
  };
  const jsonBody = JSON.stringify(pingData);

  let response;
  try {
    response = await fetch(`${API_URL}/pings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: jsonBody,
    });
  } catch (error) {
    console.error("Failed to post ping: ", error);
    throw { type: 'network', message: "An unexpected network error occurred" };
  }

  if (!response.ok) {
    if (response.status == 403) {
      const json_response = await response.json();
      const message = json_response?.error?.messages?.error;
      throw { type: "forbidden", message };
    }
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const { data: { ping } } = await response.json();
  return ping;
};

export const listPings = async (params) => {
  try {
    const token = await getToken();
    if (token == undefined) {
      throw new Error("current user is not logged in!");
    }

    const listParams = queryString.stringify(params);

    const response = await fetch(`${API_URL}/pings?${listParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching recent pings:', error);
    return { "pings": [] };
  }
};

export const listFollowingPings = async (params) => {
  try {
    const token = await getToken();
    if (token == undefined) {
      throw new Error("current user is not logged in!");
    }

    const followingParams = queryString.stringify(params);

    const response = await fetch(`${API_URL}/following_pings?${followingParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching recent pings:', error);
  }
};

export const listPingsForUser = async (params) => {
  try {
    const { username } = params;
    const token = await getToken();
    const userParams = queryString.stringify(params);

    const response = await fetch(`${API_URL}/users/${username}/pings?${userParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    console.log("Got some data for user:", username, data);
    return data;
  } catch (error) {
    console.error('Error fetching recent pings:', error);
  }
};

export const likePing = async (pingId) => {
  try {
    const token = await getToken();
    const jsonBody = JSON.stringify({ ping_id: pingId });
    console.log("posting json:", jsonBody);

    const response = await fetch(`${API_URL}/likes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: jsonBody,
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    const newPing = await response.json();
    return newPing;
  } catch (error) {
    console.log('Error posting like:', error);
    throw error;
  }
};

export const unLikePing = async (pingId) => {
  try {
    const token = await getToken();
    const jsonBody = JSON.stringify({ ping_id: pingId });
    console.log("deleting json:", jsonBody);

    const response = await fetch(`${API_URL}/likes`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: jsonBody,
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    const newPing = await response.json();
    return newPing;
  } catch (error) {
    console.log('Error deleting like:', error);
    throw error;
  }
};

export const getPing = async (pingId) => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_URL}/pings/${pingId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    console.log("Got some data for user:", data, data);
    return data.data.ping;
  } catch (error) {
    console.error('Error fetching recent pings:', error);
  }
}

export const listPingReplies = async (ping_id) => {
  try {
    const token = await getToken();

    const response = await fetch(`${API_URL}/pings/${ping_id}/replies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    console.log("Got some data for pings:", ping_id, data);
    return data.pings;
  } catch (error) {
    console.error('Error fetching ping replies:', error);
  }
};

export const deletePing = async (pingId) => {
  try {
    const token = await getToken();
    const jsonBody = JSON.stringify({ public_id: pingId });
    const response = await fetch(`${API_URL}/pings/delete-ping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: jsonBody,
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log('Error deleting ping:', error);
    throw error;
  }
};
