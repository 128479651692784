import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, TextInput, Title, Snackbar, HelperText } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { sendPasswordResetEmail } from '../api/auth';  // replace this with the appropriate import for your API
import validator from 'validator';

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const linkTo = useLinkTo();

  const validateInputs = () => {
    setEmailError('');

    let valid = true;

    if (!validator.isEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    }

    return valid;
  };

  const handleSendPasswordResetEmail = async () => {
    if (!validateInputs()) {
      console.log('Validation error');
      return;
    }

    setLoading(true);

    try {
      const response = await sendPasswordResetEmail(email);

      if (response.ok) {
        setEmailSent(true);
        setSnackbarMessage('If a user with that email exists, check your email inbox.');
        setSnackbarVisible(true);
      } else {
        const errorMessage = await response.text();
        setSnackbarMessage(errorMessage);
        setSnackbarVisible(true);
        console.log('Error sending password reset email');
      }
    } catch (error) {
      setSnackbarMessage('Something went wrong. Please try again.');
      setSnackbarVisible(true);
      console.log('Error sending password reset email:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    linkTo('/login');
  };

  return (
    <View style={styles.container}>
      <Title style={styles.title}>Forgot Password</Title>
      <TextInput
        label="Email"
        value={email}
        onChangeText={setEmail}
        autoCapitalize='none'
        error={emailError}
        style={styles.input}
      />
      <HelperText type="error" visible={emailError}>{emailError}</HelperText>
      {emailSent ? (
        <Text style={styles.text}>Check your email for a link to reset your password.</Text>
      ) : (
        <Button
          mode="contained"
          onPress={handleSendPasswordResetEmail}
          style={styles.button}
          loading={loading}
          disabled={loading}
        >
          Send Password Reset Email
        </Button>
      )}
      <Button
            mode="outlined"
            onPress={handleCancel}
            style={styles.button}
          >
            Back to Login
          </Button>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={Snackbar.DURATION_SHORT}
      >
        {snackbarMessage}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  text: {
    marginTop: 16,
    textAlign: 'center',
  },
});