import { Platform } from 'react-native';
import { API_URL } from './config';
import { getToken } from './auth';

export async function uploadImage(blobUri) {
  const token = await getToken();
  const formData = new FormData();

  if (Platform.OS == "web") {
    const response = await fetch(blobUri);
    console.log("Got this response: ", response);
    const imageBlob = await response.blob();
    if (imageBlob == null) {
      console.log("The image blob is null");
      throw new Error("bad blob");
    }
    formData.append('avatar', imageBlob);
  } else {
    formData.append('avatar', { name: 'avatar.jpg', type: 'image/jpg', uri: blobUri })
  }

  let result = await fetch(`${API_URL}/user/avatar`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData, // Use formData here
  });

  console.log("Upload result:", result);
}