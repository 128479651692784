import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, ScrollView, View, Platform, Text } from 'react-native';
import { ActivityIndicator, IconButton } from 'react-native-paper';
import PingCardContainer from '../containers/PingCardContainer';
import { getPing, listPingReplies } from '../api/pings';
import { useRoute } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const PingPage = () => {
  const [ping, setPing] = useState(undefined);
  const [pingReplies, setPingReplies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPingRepliesLoading, setIsPingRepliesLoading] = useState(true);
  const route = useRoute();
  const { ping_id } = route.params;
  const scrollViewRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const [ping] = await Promise.all([getPing(ping_id)]);
        setPing(ping);
      } catch (error) {
        console.error('Failed to get ping', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ping_id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsPingRepliesLoading(true);
      try {
        const [pingReplies] = await Promise.all([listPingReplies(ping_id)]);
        setPingReplies(pingReplies);
      } catch (error) {
        console.error('Failed to get ping replies', error);
      } finally {
        setIsPingRepliesLoading(false);
      }
    };

    fetchData();
  }, [ping_id]);

  useEffect(() => {
    if (targetRef.current && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: targetRef.current.offsetTop,
        animated: false,
      });
    }
  }, [isPingRepliesLoading]);

  return (
    <View style={styles.container}>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          {isLoading && <ActivityIndicator animating={true} />}
        </View>
      ) : (
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          ref={scrollViewRef}
        >
          {ping?.parent_ping?.id && (
            <>
              <View style={styles.parentPingContent}>
                <PingCardContainer ping={ping.parent_ping} />
              </View>
              <View style={styles.dotSpacer}>
                <IconButton
                  style={{ margin: 0 }}
                  icon={({ size, color }) => (
                    <MaterialCommunityIcons
                      name="dots-vertical"
                      size={size}
                      color={color}
                    />
                  )}
                />
              </View>
            </>
          )}
          <View style={styles.content} ref={targetRef}>
            <PingCardContainer ping={ping} />
          </View>
          <View style={styles.pingReplyContent}>
            {!isPingRepliesLoading && pingReplies?.length > 0 && (
              <View style={styles.repliesText}>
                <Text>Replies</Text>
              </View>
            )}
            {pingReplies.map((pingReply, index) => (
              <PingCardContainer key={index} ping={pingReply} />
            ))}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  contentContainer: {
    paddingBottom: 400,
  },
  content: {
    padding: 8,
  },
  repliesText: {
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  dotSpacer: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  pingReplyContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  parentPingContent: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 15,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
  },
});

export default PingPage;
