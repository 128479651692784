import { getUserInfo, getToken } from './auth';
import { API_URL } from './config';

export const getUserAvatarURLOrDefault = async () => {
  const user_info = getUserInfo();
  if (!user_info || !user_info.avatar_url) {
    return null;
  }

  return user_info.avatar_url;
};

export const loadUserProfile = async (username) => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_URL}/users/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log('Got user profile:', username, data);
    return data;
  } catch (error) {
    console.error('Error fetching recent user profile:', error);
  }
};

export const followUser = async (username) => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_URL}/users/${username}/follow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log('Followed user:', username, data);
    return data;
  } catch (error) {
    console.error('Error following user:', error);
  }
};

export const unfollowUser = async (username) => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_URL}/users/${username}/follow`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log('Unfollowed user:', username, data);
    return data;
  } catch (error) {
    console.error('Error unfollowing user:', error);
  }
};

export const updatePushToken = async (push_token) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(`${API_URL}/user/push_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ push_token }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    // TODO: Better error messages
    throw new Error(errorMessage);
  }
};

export const updatePushNotificationsEnabled = async (
  username,
  pushNotificationsEnabled
) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(
    `${API_URL}/users/${username}/push-notifications-enabled`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        push_notifications_enabled: pushNotificationsEnabled,
      }),
    }
  );

  const user = await response.json();

  if (!response.ok) {
    const errorMessage = await response.text();
    // TODO: Better error messages
    throw new Error(errorMessage);
  } else {
    return user.data;
  }
};

export const changePassword = async (currentPassword, newPasword) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(`${API_URL}/users/change-password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      current_password: currentPassword,
      new_password: newPasword,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  } else {
    return data;
  }
};

export const deleteAccount = async () => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(`${API_URL}/users/delete-account`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  } else {
    return data;
  }
};

export const blockUser = async (username) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(`${API_URL}/users/${username}/block`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  } else {
    return data;
  }
};

export const unblockUser = async (username) => {
  const token = await getToken();
  if (token == undefined) {
    throw new Error('current user is not logged in!');
  }

  const response = await fetch(`${API_URL}/users/${username}/unblock`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  } else {
    return data;
  }
};

export const getPinguinsByUser = async (username) => {
  try {
    const token = await getToken();
    if (token == undefined) {
      throw new Error('current user is not logged in!');
    }

    const response = await fetch(`${API_URL}/users/${username}/pinguins`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching recent pings:', error);
    return { pinguins: [] };
  }
};
