import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, ScrollView, Platform } from 'react-native';
import {
  ActivityIndicator,
  Card,
  Avatar,
  Divider,
  FAB,
} from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { getUserInfo } from '../api/auth';
import { getPinguinsByUser } from '../api/user';
import Icon from '../assets/icon.svg';

function PinguinCard({ pinguin }) {
  return (
    <Card style={styles.userCard}>
      <Card.Title
        title={
          <View>
            <Text style={styles.displayName}>{pinguin.display_name}</Text>
            <Text style={styles.username}>{` @${pinguin.username}`}</Text>
          </View>
        }
        left={() => (
          <Avatar.Image
            size={40}
            source={
              pinguin.avatar_url ? { uri: `${pinguin.avatar_url}_64` } : null
            }
          />
        )}
      />
    </Card>
  );
}

export default function MyPinguinsPage() {
  const [loading, setLoading] = useState(false);
  const [pinguins, setPinguins] = useState([]);
  const linkTo = useLinkTo();

  useEffect(() => {
    const fetchPinguins = async () => {
      console.log('fetching my pinguins');
      setLoading(true);

      try {
        const userInfo = await getUserInfo();
        let data = await getPinguinsByUser(userInfo.username);
        setPinguins(data.users);
      } catch (error) {
        console.error('Failed to list pinguins', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPinguins();
  }, []);

  return (
    <View style={styles.container}>
      {loading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator animating={true} />
        </View>
      )}
      <ScrollView>
        {pinguins.map(
          (
            pinguin,
            index // Map through the list of pinguins and render a PinguinCard for each
          ) => (
            <React.Fragment key={index}>
              <PinguinCard key={index} pinguin={pinguin} />
              <Divider key={`divider_${index}`} />
            </React.Fragment>
          )
        )}
      </ScrollView>
      <FAB
        style={Platform.OS === 'web' ? styles.fabWeb : styles.fab}
        icon={() => <Icon width={26} height={26} color="white" />}
        label="Create New Pinguin!"
        onPress={() => {
          linkTo('/newpinguin');
        }}
        color="white"
        theme={{ isV3: true }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  userCard: {
    // Styles for the user card
  },
  displayName: {
    fontWeight: 'bold',
  },
  username: {
    color: 'gray',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
  fabWeb: {
    position: 'fixed',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
});
