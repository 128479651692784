import React from 'react';
import { View,  StyleSheet } from 'react-native';
import { Text, TouchableRipple, useTheme } from 'react-native-paper';

export default function DrawerButton({ onPress }) {
  const theme = useTheme();
  const font = theme.fonts.labelLarge;

  return (
    <TouchableRipple
        onPress={onPress}
        borderless
        style={styles.container}
      >
        <View style={styles.buttonWrapper}>
          <View style={styles.buttonContent}>
            <Text
              variant='labelLarge'
              numberOfLines={1}
              style={[
                styles.label,
              ]}
            >
              New Ping
            </Text>
          </View>
        </View>
      </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    marginVertical: 12,
    backgroundColor: '#FF48B1',
    borderRadius: 28,
    height: 56,
    justifyContent: 'center',
  },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  pingButton: {
    borderRadius: 7,
  },
  label: {
    fontSize: 17,
    color: 'white',
  }
});
