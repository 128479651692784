import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
  Dimensions,
  FlatList,
  RefreshControl,
} from 'react-native';
import { FAB, ActivityIndicator } from 'react-native-paper';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';
import { getToken } from '../api/auth';
import { listPings, listFollowingPings } from '../api/pings';
import { updatePushToken } from '../api/user';
import PingCardContainer from '../containers/PingCardContainer';
import { PingContext } from '../contexts/PingContext';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import * as Sentry from 'sentry-expo';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const ExploreTab = () => {
  const { pingData } = useContext(PingContext);
  const { setPingData } = useContext(PingContext);
  const navigation = useNavigation();

  const [state, setState] = useState({
    pings: [],
    loading: false,
    cursor: {
      cursor_after: undefined,
      cursor_before: undefined,
    },
    page: 1,
    hasMore: true,
    refreshing: false,
  });

  const height = Dimensions.get('window').height;

  useEffect(() => {
    const fetchPings = async () => {
      if (state.loading || !state.hasMore) {
        return;
      }
      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        const token = await getToken();
        if (token == null) {
          navigation.navigate('LoginLanding');
          return;
        }

        const data = await listPings(state.cursor);

        if (data?.pings?.length > 0) {
          setState((prevState) => ({
            ...prevState,
            pings: [...prevState.pings, ...data.pings],
            cursor: {
              cursor_after: !!data.cursor_after ? data.cursor_after : undefined,
              cursor_before: !!data.cursor_before
                ? data.cursor_before
                : undefined,
            },
          }));
        } else {
          setState((prevState) => ({ ...prevState, hasMore: false }));
        }

        if (data?.total_count && state.pings.length >= data.total_count) {
          setState((prevState) => ({ ...prevState, hasMore: false }));
        }
      } catch (error) {
        console.error('Failed to list pings', error);
        Sentry.Native.captureException(error);
      } finally {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          refreshing: false,
        }));
      }
    };

    fetchPings();
  }, [state.page]);

  const handleRefresh = useCallback(async () => {
    setState((prevState) => ({ ...prevState, refreshing: true }));
    try {
      const data = await listPings({
        cursor_after: undefined,
        cursor_before: undefined,
      });

      if (data?.pings?.length > 0) {
        setPingData(null);
        setState((prevState) => ({
          ...prevState,
          pings: [...data.pings],
          cursor: {
            cursor_after: !!data.cursor_after ? data.cursor_after : undefined,
            cursor_before: !!data.cursor_before
              ? data.cursor_before
              : undefined,
          },
        }));
      } else {
        setState((prevState) => ({ ...prevState, hasMore: false }));
      }

      if (data?.total_count && state.pings.length >= data.total_count) {
        setState((prevState) => ({ ...prevState, hasMore: false }));
      }
    } catch (error) {
      console.error('Failed to list pings', error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        refreshing: false,
      }));
    }
  }, []);

  const handleLoadMore = () => {
    if (!state.loading && state.hasMore) {
      setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
    }
  };

  const renderFooter = () => {
    if (!state.loading || !state.hasMore) return null;

    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator animating={true} />
      </View>
    );
  };

  return (
    <View style={{ height: height, paddingBottom: 56 }}>
      {state.loading && (
        <View style={styles.footer}>
          <ActivityIndicator size="small" color="#FF48B1" />
        </View>
      )}
      <FlatList
        data={pingData ? [pingData, ...state.pings] : state.pings}
        renderItem={({ item }) => <PingCardContainer ping={item} />}
        keyExtractor={(item, index) => index.toString()}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        ListFooterComponent={renderFooter}
        contentContainerStyle={styles.content}
        refreshControl={
          <RefreshControl
            refreshing={state.refreshing}
            onRefresh={handleRefresh}
            colors={['#FF48B1']}
            progressBackgroundColor="#ffffff"
          />
        }
      />
    </View>
  );
};

const FollowingTab = () => {
  const { setPingData } = useContext(PingContext);
  const navigation = useNavigation();

  const [state, setState] = useState({
    pings: [],
    loading: false,
    cursor: {
      cursor_after: undefined,
      cursor_before: undefined,
    },
    page: 1,
    hasMore: true,
    refreshing: false,
  });

  const height = Dimensions.get('window').height;

  useEffect(() => {
    const fetchRecentPings = async () => {
      if (state.loading || !state.hasMore) {
        return;
      }
      setState((prevState) => ({ ...prevState, loading: true }));
      try {
        const token = await getToken();
        if (token == null) {
          navigation.navigate('LoginLanding');
          return;
        }

        const data = await listFollowingPings(state.cursor);

        if (data?.pings?.length > 0) {
          setPingData(null);
          setState((prevState) => ({
            ...prevState,
            pings: [...prevState.pings, ...data.pings],
            cursor: {
              cursor_after: !!data.cursor_after ? data.cursor_after : undefined,
              cursor_before: !!data.cursor_before
                ? data.cursor_before
                : undefined,
            },
          }));
        } else {
          setState((prevState) => ({ ...prevState, hasMore: false }));
        }

        if (data?.total_count && state.pings.length >= data.total_count) {
          setState((prevState) => ({ ...prevState, hasMore: false }));
        }
      } catch (error) {
        console.error('Failed to list following pings', error);
      } finally {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          refreshing: false,
        }));
      }
    };

    fetchRecentPings();
  }, [state.page]);

  const handleRefresh = useCallback(async () => {
    setState((prevState) => ({ ...prevState, refreshing: true }));
    try {
      const data = await listFollowingPings({
        cursor_after: undefined,
        cursor_before: undefined,
      });

      if (data?.pings?.length > 0) {
        setPingData(null);
        setState((prevState) => ({
          ...prevState,
          pings: [...data.pings],
          cursor: {
            cursor_after: !!data.cursor_after ? data.cursor_after : undefined,
            cursor_before: !!data.cursor_before
              ? data.cursor_before
              : undefined,
          },
        }));
      } else {
        setState((prevState) => ({ ...prevState, hasMore: false }));
      }

      if (data?.total_count && state.pings.length >= data.total_count) {
        setState((prevState) => ({ ...prevState, hasMore: false }));
      }
    } catch (error) {
      console.error('Failed to list pings', error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        refreshing: false,
      }));
    }
  }, []);

  const handleLoadMore = () => {
    if (!state.loading && state.hasMore) {
      setState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
    }
  };

  const renderFooter = () => {
    if (!state.loading || !state.hasMore) return null;

    return (
      <View style={styles.footer}>
        <ActivityIndicator size="small" color="#FF48B1" animating={true} />
      </View>
    );
  };

  return (
    <View style={{ height: height, width: '100%', paddingBottom: 56 }}>
      {state.loading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="small" color="#FF48B1" animating={true} />
        </View>
      )}
      {
        state.pings.length > 0 ? (
          <FlatList
            data={state.pings}
            renderItem={({ item }) => <PingCardContainer ping={item} />}
            keyExtractor={(item, index) => index.toString()}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.5}
            ListFooterComponent={renderFooter}
            contentContainerStyle={styles.content}
            refreshControl={
              <RefreshControl
                refreshing={state.refreshing}
                onRefresh={handleRefresh}
                colors={['#FF48B1']} // Customize the color of the loading spinner
                progressBackgroundColor="#ffffff" // Set the background color of the loading spinner
              />
            }
          />
        ) : <Text>Follow some users!</Text>
      }

    </View>
  );
};

const renderTabBar = (props) => {
  const inputRange = props.navigationState.routes.map((x, i) => i);

  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: '#FF48B1' }}
      style={{
        backgroundColor: 'white',
      }}
      renderLabel={({ route, focused, color }) => (
        <Text
          style={{
            color: '#FF48B1',
            margin: 8,
            fontSize: 18,
          }}
        >
          {route.title}
        </Text>
      )}
    />
  );
};

async function registerForPushNotificationsAsync() {
  if (!Device.isDevice || Platform.OS == 'web') {
    return;
  }

  try {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      if (status !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return;
      }
    }

    let token = await Notifications.getExpoPushTokenAsync();

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    if (token) {
      await updatePushToken(token.data);
    }
  } catch (error) {
    console.log("Error registering for push: ", error);

    if (Platform.OS != "web") {
      Sentry.Native.captureException(error);
    }
  }
}

export default function HomePage() {
  const layout = useWindowDimensions();
  const linkTo = useLinkTo();
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'explore', title: 'Explore' },
    { key: 'following', title: 'Following' },
  ]);

  const renderScene = SceneMap({
    explore: ExploreTab,
    following: FollowingTab,
  });

  useEffect(() => {
    registerForPushNotificationsAsync();
  }, []);

  return (
    <View style={styles.container}>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        style={styles.tabView}
      />
      <FAB
        style={Platform.OS === 'web' ? styles.fabWeb : styles.fab}
        icon="plus"
        onPress={() => {
          linkTo('/newping');
        }}
        color="white"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 8,
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  pingInput: {
    marginBottom: 16,
  },
  postButton: {
    marginBottom: 16,
  },
  pingCard: {
    marginBottom: 16,
  },
  usernameLink: {
    textDecorationLine: 'none',
    color: 'inherit',
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
  fabWeb: {
    position: 'fixed',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: '#FF48B1',
    color: 'white',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    paddingVertical: 16, // Add some vertical padding to center the spinner
  },
  tabBar: {
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
  },
  tabView: {},
  footer: {
    marginTop: 10,
    alignItems: 'center',
  },
});
