import React from 'react';
import { Card, Title, Paragraph } from 'react-native-paper';
import { StyleSheet, Image, TouchableOpacity, Linking } from 'react-native';

const shortenDescription = (ogData) => {
  let desc = ogData.open_graph_description;
  if (desc == null) {
    desc = ogData.url;
  }
  return desc.length > 200 ? `${desc.slice(0, 200)}...` : desc;
}

export const OpenGraphCard = ({ ogData }) => {
  const handlePress = () => {
    Linking.openURL(ogData.open_graph_url);
  };

  if (ogData.open_graph_title == null) {
    return null;
  }

  const description = shortenDescription(ogData);

  return (
    <TouchableOpacity onPress={handlePress}>
      <Card style={styles.card}>
        <Card.Content>
          <Title>{ogData.open_graph_title}</Title>
          <Paragraph>{description}</Paragraph>
        </Card.Content>
        { ogData.open_graph_image && <Card.Cover style={styles.image} source={{ uri: ogData.open_graph_image }} /> }
      </Card>
    </TouchableOpacity>
  );
};


const styles = StyleSheet.create({
  card: {
    margin: 8,
    borderRadius: 4,
  },
  image: {
    height: 200, // Fixed height
    marginTop: 8,
    resizeMode: 'cover', // Cover the space while maintaining the aspect ratio of the image.
  },
});

export default OpenGraphCard;
