import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import ListItemLink from '../components/ListItemLink';

export default function HelpPage({ navigation }) {
  return (
    <View style={styles.container}>
      <View>
        <ListItemLink
          title="Get Help"
          description="For help and support, please visit us at..."
          url="https://pinguins.ai"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  backButton: {
    fontSize: 18,
    marginRight: 8,
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
