import { getToken } from "./auth";
import { API_URL } from "./config";

export const reportContent = async (id, type, reason = '') => {
  try {
    const token = await getToken();
    const response = await fetch(`${API_URL}/report-content`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ content_id: id, type: type, reason: reason}),
    });
    const data = await response.json();
    console.log("Reported content", data);
    return data;
  } catch (error) {
    console.error('Error reporting content:', error);
  }
}