import React, { useState } from 'react';
import { StyleSheet, View, KeyboardAvoidingView, Platform, ScrollView, SafeAreaView, Linking } from 'react-native';
import { Button, TextInput, Title, Snackbar, HelperText, Text } from 'react-native-paper';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import validator from 'validator';
import { AuthContext } from '../contexts/AuthContext';

export default function CreateAccountPage() {
  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  const [phoneOrEmailError, setPhoneOrEmailError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const linkTo = useLinkTo();
  const { signUp } = React.useContext(AuthContext);
  const navigation = useNavigation();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[-_!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && (hasUpperCase || hasDigit || hasSpecialChar);
  };

  const validateInputs = () => {
    const isValidEmail = validator.isEmail(phoneOrEmail);
    const isPasswordValid = validatePassword(password);

    setDisplayNameError('');
    setPhoneOrEmailError('');
    setUsernameError('');
    setPasswordError('');

    let valid = true;

    if (!displayName) {
      setDisplayNameError('Display name cannot be empty.');
      valid = false;
    }

    if (!isValidEmail) {
      setPhoneOrEmailError('Please provide a valid email address.');
      valid = false;
    }

    if (!username) {
      setUsernameError('Username cannot be empty.');
      valid = false;
    }

    if (!isPasswordValid) {
      setPasswordError('Please select a more complex password, including at least one uppercase, number or special character.');
      valid = false;
    }

    return valid;
  };

  const handleCreateAccount = async () => {
    if (!validateInputs()) {
      console.log('Validation error');
      return;
    }

    setLoading(true);

    try {
      let error = await signUp({
        display_name: displayName,
        phone_or_email: phoneOrEmail,
        username,
        password,
      });

      if (error == undefined) {
        navigation.navigate('Main', { screen: 'Home' });
        return;
      }

      if (error?.type == 'invalid_input') {
        if ("username" in error.messages) {
          setUsernameError("Username " + error.messages["username"]);
        }
        if ("email" in error.messages) {
          setPhoneOrEmailError("Email " + error.messages["email"]);
        }
        if ("display_name" in error.messages) {
          setDisplayNameError("Display Name " + error.messages["display_name"]);
        }
        if ("password" in error.messages) {
          setPasswordError("Password " + error.messages["password"]);
        }
        if ("error" in error.messages) {
          setSnackbarMessage(error.messages?.["error"]);
          setSnackbarVisible(true);
        }
      } else {
        console.log("Setting the snackbar");
        setSnackbarMessage(error.messages?.["error"]);
        setSnackbarVisible(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    linkTo('/landing');
  };

  return (
    <SafeAreaView style={styles.safeContainer}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.safeContainer}
      >
        <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} keyboardShouldPersistTaps="handled">
          <Title style={styles.title}>Create Account</Title>
          <TextInput
            label="Name"
            value={displayName}
            onChangeText={setDisplayName}
            error={displayNameError}
            style={styles.input}
          />
          {
            displayNameError ?
              <HelperText type="error" visible={displayNameError}>
                {displayNameError}
              </HelperText> : null
          }
          <TextInput
            label="Email Address"
            value={phoneOrEmail}
            onChangeText={setPhoneOrEmail}
            keyboardType="default"
            error={phoneOrEmailError}
            style={styles.input}
          />
          {
            phoneOrEmailError ? <HelperText
              type="error"
              visible={phoneOrEmailError}>
              {phoneOrEmailError}
            </HelperText> : null
          }
          <TextInput
            label="Username"
            value={username}
            onChangeText={setUsername}
            error={usernameError}
            style={styles.input}
          />
          {usernameError ? <HelperText type="error" visible={usernameError}>{usernameError}</HelperText> : null}
          <TextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            error={passwordError}
            style={styles.input}
          />
          {passwordError ? <HelperText type="error" visible={passwordError}>{passwordError}</HelperText> : null}
          <Button
            mode="contained"
            onPress={handleCreateAccount}
            style={styles.button}
            loading={loading}
            disabled={loading}
          >
            Create Account
          </Button>
          <Button
            mode="outlined"
            onPress={handleCancel}
            style={styles.button}
          >
            Cancel
          </Button>
          <View style={styles.tosView}>
            <Text style={{ color: 'black', fontSize: 16 }}>
              By creating an account, you agree to Pinguins Ai's
              <Text style={{ color: 'blue' }}
                onPress={() => Linking.openURL('https://pinguins.ai/terms-and-conditions.html')}>
                {' '}Terms and Conditions{' '}
              </Text>
              and acknowledge that you have read and understand our
              <Text style={{ color: 'blue' }}
                onPress={() => Linking.openURL('https://pinguins.ai/privacy-policy.html')}>
                {' '}Privacy Policy.
              </Text>
            </Text>
          </View>
          <Snackbar
            visible={snackbarVisible}
            onDismiss={() => setSnackbarVisible(false)}
            duration={Snackbar.DURATION_SHORT}
          >
            {snackbarMessage}
          </Snackbar>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeContainer: {
    flex: 1,
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    flex: 1,
    padding: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  hidden: {
    visible: false,
  },
  tosView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4
  },
});
