import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import SettingsPage from '../pages/SettingsPage';
import NotificationSettings from '../pages/NotificationSettingsPage';
import AccountSettings from '../pages/AccountSettingsPage';
import HelpPage from '../pages/HelpPage';
import AboutPage from '../pages/AboutPage';

import PinguinAppbar from '../components/PinguinAppbar';

const Stack = createStackNavigator();

export default function SettingsStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => (
          <PinguinAppbar {...props} />
        ),
      }}
    >
      <Stack.Screen name="SettingsPage" component={SettingsPage} />
      <Stack.Screen name="NotificationSettings" component={NotificationSettings} />
      <Stack.Screen name="AccountSettings" component={AccountSettings} />
      <Stack.Screen name="Help" component={HelpPage} />
      <Stack.Screen name="About" component={AboutPage} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  appbarWeb: {
    position: 'relative',
    width: '100%',
    zIndex: 1000,
  },
});
