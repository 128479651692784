import React from "react";
import { StyleSheet, Text } from "react-native";
import { Card, Paragraph, Avatar, TouchableRipple } from "react-native-paper";
import { useNavigation, useLinkTo } from "@react-navigation/native";

const QuotedPingCard = ({
  username,
  displayName,
  avatarUrl,
  content,
  pingId,
  deletedAt,
}) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const handleProfilePress = () => {
    navigation.navigate("Profile", { username: username });
  };

  return (
    <>
      {!!deletedAt ? (
        <Card style={styles.pingCard}>
          <Card.Content style={styles.deletedContent}>
            <Paragraph>[Deleted]</Paragraph>
          </Card.Content>
        </Card>
      ) : (
        <Card
          style={styles.pingCard}
          onPress={() => linkTo(`/pings/${pingId}`)}
        >
          {!!username ? (
            <>
              <TouchableRipple onPress={handleProfilePress}>
                <Card.Title
                  title={
                    <>
                      <Text style={styles.displayName}>{displayName}</Text>
                      <Text style={styles.username}>{` @${username}`}</Text>
                    </>
                  }
                  left={() => (
                    <Avatar.Image
                      size={40}
                      source={avatarUrl ? { uri: `${avatarUrl}_64` } : null}
                    />
                  )}
                />
              </TouchableRipple>
            </>
          ) : (
            <Text style={styles.deletedUsername}>[deleted]</Text>
          )}
          <Card.Content style={styles.pingContent}>
            <Paragraph>{content}</Paragraph>
          </Card.Content>
        </Card>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pingCard: {
    marginBottom: 8,
    borderRadius: 0,
  },
  pingContent: {
    marginLeft: 56, // Avatar width 40, content padding 16
  },
  displayName: {},
  username: {
    color: "#777",
  },
  spacer: {
    flexGrow: 1,
  },
  deletedUsername: {
    color: "#919090",
  },
  deletedContent: {
    marginLeft: 10,
  },
});

export default QuotedPingCard;
