import { API_URL } from './config';
import { getToken } from './auth';

export const listNotifications = async () => {
  try {
    const token = await getToken();
    if (token == undefined) {
      throw new Error("current user is not logged in!");
    } 

    const response = await fetch(`${API_URL}/notifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    const data = await response.json();
    console.log("Got notifications:", data);
    return data.notifications;
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
};
