import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Modal,
  TouchableOpacity,
  Share,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  Card,
  Paragraph,
  IconButton,
  Tooltip,
  Avatar,
  TouchableRipple,
  Snackbar,
} from 'react-native-paper';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Localization from 'expo-localization';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Clipboard from 'expo-clipboard';

import { getUserInfo } from '../api/auth';
import { deletePing } from '../api/pings';
import { loadOpenGraph } from '../api/opengraph';
import OpenGraphCard from './OpenGraphCard';
import QuotedPingCard from './QuotedPingCard';
import { reportContent } from '../api/reportContent';

const PingCard = ({ ping, onLikePress, isLiked }) => {
  // XXX: Time formatting currently broken on web due to: https://github.com/expo/expo/pull/22003
  const parsedDate = parseISO(ping.inserted_at);
  const locales = Localization.getCalendars();
  const userTimezone = locales[0].timeZone;
  const localDate = utcToZonedTime(parsedDate, userTimezone);
  const relativeDate = formatDistanceToNow(localDate, { addSuffix: true });
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const { quoted_ping, parent_ping } = ping;

  // OpenGraph support
  const [ogData, setOgData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [isReported, setIsReported] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserInfo(userInfo);

        if (ping.link_url) {
          setIsLoading(true);

          const data = await loadOpenGraph(ping.link_url);
          if (isMounted) {
            setOgData(data);
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (isMounted) {
          setHasError(true);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [ping.link_url]);

  const handleProfilePress = () => {
    navigation.navigate('Profile', { username: ping.username });
    //linkTo({ screen: "Profile", params: { username: ping.username } });
  };

  const handleParentPingProfilePress = () => {
    linkTo({ screen: 'Profile', params: { username: parent_ping.username } });
  };

  const handleExternalLinkPress = () => {
    linkTo({ screen: 'ExternalLink', params: { url: ping.link_url } });
  };

  const handleDismissOverlay = () => {
    setIsReported(false);
  };

  const DeletedUser = () => {
    return <Text style={styles.deletedUsername}>[deleted]</Text>;
  };

  const handleMenuPress = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleDeletePing = async () => {
    setIsModalLoading(true);

    try {
      await deletePing(ping.id);
      setIsModalVisible(false);
      linkTo({ screen: 'HomeScreen' });
    } catch (error) {
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleReportPing = async () => {
    setIsModalLoading(true);

    try {
      await reportContent(ping.id, 'ping');
      setIsReported(true);
      setIsModalVisible(false);
      // Report content success message
    } catch (error) {
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleSharePing = async () => {
    try {
      const ping_url = `https://app.pinguins.ai/pings/${ping.id}`;
      if (Platform.OS == 'web') {
        // TODO: Move this snackbar logic out into a reducer for the page
        Clipboard.setStringAsync(ping_url);
        setLinkCopied(true);
      } else {
        const result = await Share.share({
          message: `Check out this Ping: ${ping_url}`,
          url: ping_url,
        });
        if (result.action === Share.sharedAction) {
          console.log('Share result: ', result);
        } else if (result.action === Share.dismissedAction) {
          // dismissed, nothing to do
        }
      }
    } catch (error) {
      console.log('Share failed:', error);
      Alert.alert(error.message);
    }
  };

  return (
    <View>
      {!!ping.deleted_at ? (
        <Card style={styles.pingCard}>
          <Card.Content style={styles.deletedContent}>
            <Paragraph>[Deleted]</Paragraph>
          </Card.Content>
        </Card>
      ) : (
        <Card
          style={styles.pingCard}
          onPress={() => linkTo(`/pings/${ping.id}`)}
        >
          <View>
            <Card.Title
              style={styles.title}
              title={
                <>
                  {!!ping.username ? (
                    <>
                      <Text onPress={handleProfilePress}>
                        {ping.display_name}
                      </Text>
                      <View style={styles.displayNameSpace} />
                      <Text
                        onPress={handleProfilePress}
                        style={styles.username}
                      >
                        @{ping.username}
                      </Text>
                    </>
                  ) : (
                    <>
                      <DeletedUser />
                    </>
                  )}
                </>
              }
              subtitle={
                <>
                  {!!parent_ping?.id ? (
                    <>
                      {!!parent_ping.username ? (
                        <Text onPress={handleParentPingProfilePress}>
                          {relativeDate} in reply to @{parent_ping.username}
                        </Text>
                      ) : (
                        <Text>{relativeDate}</Text>
                      )}
                    </>
                  ) : (
                    <Text>{relativeDate}</Text>
                  )}
                </>
              }
              left={() => (
                <Avatar.Image
                  size={40}
                  source={
                    ping.avatar_url ? { uri: `${ping.avatar_url}_64` } : null
                  }
                />
              )}
            />
            <View style={styles.menuButtonContainer}>
              <IconButton
                icon="dots-horizontal"
                color="#000"
                onPress={handleMenuPress}
              />
            </View>
          </View>
          <Card.Content style={styles.pingContent}>
            <View>
              <View>
                <Paragraph>{ping.content}</Paragraph>
              </View>
              {!!quoted_ping?.id ? (
                <View style={styles.quotedPing}>
                  <QuotedPingCard
                    username={quoted_ping.username}
                    displayName={quoted_ping.display_name}
                    avatarUrl={quoted_ping.avatar_url}
                    content={quoted_ping.content}
                    pingId={quoted_ping.id}
                    deletedAt={quoted_ping.deleted_at}
                  />
                </View>
              ) : null}
            </View>

            {ping.link_url && !isLoading && !hasError && (
              <OpenGraphCard ogData={ogData} />
            )}
            {ping.link_url && isLoading && (
              <ActivityIndicator size="small" color="#0000ff" />
            )}
            {ping.link_url && !isLoading && hasError && (
              <TouchableRipple onPress={handleExternalLinkPress}>
                <Text>{ping.link_url}</Text>
              </TouchableRipple>
            )}
          </Card.Content>
          <Card.Actions>
            <Tooltip title="Comment">
              <IconButton
                accessibilityLabel="comment"
                icon={({ size, color }) => (
                  <MaterialCommunityIcons
                    name="comment-outline"
                    size={size}
                    color={color}
                    onPress={() => {
                      linkTo(
                        `/newping?parent=${encodeURIComponent(
                          JSON.stringify(ping)
                        )}`
                      );
                    }}
                  />
                )}
                onPress={() => {
                  /* TODO: Social Sharing */
                }}
              />
            </Tooltip>
            <Text>{ping.reply_count}</Text>
            <View style={styles.spacer} />
            <Tooltip title="Quote-Ping">
              <IconButton
                icon={({ size, color }) => (
                  <MaterialCommunityIcons
                    name={'access-point-plus'}
                    size={size}
                    color={color}
                  />
                )}
                onPress={() => {
                  linkTo(
                    `/newping?quoted_ping=${encodeURIComponent(
                      JSON.stringify(ping)
                    )}`
                  );
                }}
              />
            </Tooltip>
            <Text>{ping.quote_count}</Text>
            <View style={styles.spacer} />
            <Tooltip title="Like">
              <IconButton
                icon={({ size, color }) => (
                  <MaterialCommunityIcons
                    name={isLiked ? 'star' : 'star-outline'}
                    size={size}
                    color={color}
                  />
                )}
                onPress={() => {
                  onLikePress(ping.id, isLiked);
                }}
              />
            </Tooltip>
            <View style={styles.spacer} />
            <Tooltip title="Share">
              <IconButton
                icon={({ size, color }) => (
                  <MaterialCommunityIcons
                    name="share-outline"
                    size={size}
                    color={color}
                  />
                )}
                onPress={handleSharePing}
              />
            </Tooltip>
          </Card.Actions>
          <Modal visible={isModalVisible} animationType="slide" transparent>
            <View style={styles.modalContainer}>
              <TouchableOpacity
                style={styles.overlay}
                onPress={handleCloseModal}
              />
              <View style={styles.modalContent}>
                <View style={styles.modalHeader}>
                  <Text style={styles.modalTitle}>Ping Options</Text>
                  <IconButton
                    icon="close"
                    color="#000"
                    onPress={handleCloseModal}
                  />
                </View>
                <View style={styles.modalSeparator} />
                {isModalLoading ? (
                  <ActivityIndicator size="large" color="#0000ff" />
                ) : (
                  <>
                    {ping?.username === userInfo.username ? (
                      <View style={styles.menuOption}>
                        <TouchableOpacity
                          style={styles.menuOption}
                          onPress={handleDeletePing}
                        >
                          <MaterialCommunityIcons
                            name="trash-can-outline"
                            size={24}
                            color="#000"
                          />
                          <Text style={styles.menuOptionText}>Delete</Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={styles.menuOption}>
                        <TouchableOpacity
                          style={styles.menuOption}
                          onPress={handleReportPing}
                        >
                          <MaterialCommunityIcons
                            name="alert-circle-outline"
                            color="#000"
                            size={24}
                          />
                          <Text style={styles.menuOptionText}>Report</Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </>
                )}
              </View>
            </View>
          </Modal>
          <Snackbar
            visible={linkCopied}
            onDismiss={() => setLinkCopied(false)}
            duration={Snackbar.DURATION_SHORT}
          >
            Copied Ping Link To Clipboard
          </Snackbar>
        </Card>
      )}
      {isReported && (
        <TouchableWithoutFeedback onPress={handleDismissOverlay}>
          <View style={styles.overlay}>
            <Text style={styles.successMessageText}>
              Content has been reported and will be reviewed.
            </Text>
          </View>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    paddingTop: 25,
  },
  pingCard: {
    marginBottom: 8,
    borderRadius: 0,
  },
  pingContent: {
    marginLeft: 56,
  },
  displayNameContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  displayName: {
    marginRight: 4,
    fontWeight: 'bold',
  },
  displayNameSpace: {
    padding: 2,
  },
  username: {
    color: '#777',
  },
  spacer: {
    flexGrow: 1,
  },
  quotedPing: {
    paddingTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
  },
  deletedUsername: {
    color: '#919090',
  },
  menuButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    height: '70%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 16,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  menuOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  menuOptionText: {
    fontSize: 16,
    color: '#C81919',
    fontWeight: 'bold',
    marginLeft: 8,
  },
  modalSeparator: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successMessageText: {
    color: 'rgb(41, 236, 254)',
    fontWeight: 'bold',
    padding: 10,
    backgroundColor: '#000',
  },
});

export default PingCard;
