import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Title } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import { Button, Snackbar, Avatar } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { uploadImage } from '../api/avatarUpload';
import { loadUserProfile } from '../api/user';
import { getUserInfo } from '../api/auth';

const AvatarEditorPage = () => {
  const [selectedImageUri, setSelectedImageUri] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUserInfo = await getUserInfo();
      const [userProfile] = await Promise.all([
        loadUserProfile(loggedInUserInfo?.username),
      ]);

      if (userProfile?.data?.avatar_url) {
        setAvatarUrl(`${userProfile.data.avatar_url}_128`);
      }
    };
    fetchData();
  }, [selectedImageUri]);

  const handleCancel = () => {
    setSelectedImageUri(null);
  };

  const handleImagePicker = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      let sizeInKB = 0;
      const maxAllowedSize = 5000;
      if (Platform.OS === 'web') {
        const response = await fetch(result.uri);
        const blob = await response.blob();
        sizeInKB = blob.size / 1024;
      } else {
        // mobile platform
        const fileInfo = await FileSystem.getInfoAsync(result.assets[0].uri);
        sizeInKB = fileInfo.size / 1024;
      }

      if (sizeInKB > maxAllowedSize) {
        setUploadError(
          'Selected file is too large. Please select a file less than ' +
            maxAllowedSize +
            'MB'
        );
        return;
      }

      setSelectedImageUri(result.assets[0].uri);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await uploadImage(selectedImageUri);
      setSelectedImageUri(null);
    } catch (error) {
      setUploadError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onDismissSnackBar = () => {
    setUploadError(null);
  };

  return (
    <View style={styles.container}>
      {!selectedImageUri && (
        <Avatar.Image
          size={128}
          source={{ uri: avatarUrl }}
          style={styles.avatar}
        />
      )}
      <Title style={styles.title}>Select Avatar</Title>
      {!selectedImageUri ? (
        <Button mode="contained" onPress={handleImagePicker}>
          Select Image
        </Button>
      ) : (
        <View style={styles.content}>
          <Avatar.Image
            source={selectedImageUri ? { uri: selectedImageUri } : null}
            size={300}
            style={styles.avatar_preview}
          />
          <View style={styles.buttonsContainer}>
            <Button
              mode="contained"
              icon="cloud-upload"
              onPress={handleSave}
              style={styles.saveButton}
              disabled={isLoading}
              loading={isLoading}
            >
              Save Avatar
            </Button>
            <Button
              mode="outlined"
              icon="close"
              onPress={handleCancel}
              style={styles.cancelButton}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </View>
        </View>
      )}
      <Snackbar
        visible={uploadError}
        onDismiss={onDismissSnackBar}
        action={{
          label: 'OK',
          onPress: () => {
            // Do something
          },
        }}
      >
        {uploadError}
      </Snackbar>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: Platform.OS === 'web' ? 64 : 0,
    padding: 8,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
  },
  content: {
    padding: 8,
    flexGrow: 1,
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  avatar_preview: {
    margin: 16,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  saveButton: {
    marginRight: 8,
  },
  cancelButton: {
    marginLeft: 8,
  },
});

export default AvatarEditorPage;
