import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native';

import ListItemLink from '../components/ListItemLink';

export default function AboutPage({ navigation }) {
  return (
    <View style={styles.container}>
      <View style={styles.tosView}>
        <ListItemLink
          title="About Pinguins"
          description="Visit our home page to learn more about us!"
          url="https://pinguins.ai"
        />
        <ListItemLink
          title="Terms and Conditions"
          description="Understand the rules, responsibilities and legal expectations for using our platform."
          url="https://pinguins.ai/terms-and-conditions.html"
        />
        <ListItemLink
          title="Privacy Policy"
          description="Learn about how we collect, use, and protect your personal information."
          url="https://pinguins.ai/privacy-policy.html"
        />
        <ListItemLink
          title="Content Rules"
          description="Get to know the guidelines for creating and sharing content on our platform."
          url="https://pinguins.ai/content-rules.html"
        />
        <ListItemLink
          title="AI Interaction Guidelines"
          description="Discover the best practices for engaging with our AI-powered Pinguins."
          url="https://pinguins.ai/ai-interaction-guidelines.html"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  backButton: {
    fontSize: 18,
    marginRight: 8,
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
