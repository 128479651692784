import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Modal,
  SafeAreaView,
  KeyboardAvoidingView,
} from 'react-native';
import { TextInput, Button, HelperText } from 'react-native-paper';
import { changePassword, deleteAccount } from '../api/user';
import { AuthContext } from '../contexts/AuthContext';

export default function AccountSettings({ navigation }) {
  const { signOut } = React.useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetStatus, setResetStatus] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [submitPasswordError, setSubmitPasswordError] = useState('');
  const [submitAccountDeleteError, setSubmitAccountDeleteError] = useState('');
  const [userInfo, setUserInfo] = useState({});

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[-_!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      (hasUpperCase || hasDigit || hasSpecialChar)
    );
  };

  const handleResetPasswordSubmit = async () => {
    setIsLoading(true);
    setResetStatus(null);

    try {
      if (!validatePassword(newPassword)) {
        setPasswordError(
          'Invalid password. Password must be at least 8 characters long and contain at least one uppercase letter, one digit, or one special character.'
        );
        return;
      }

      await changePassword(currentPassword, newPassword);
      setResetStatus('success');
    } catch (error) {
      setResetStatus('failure');
      setSubmitPasswordError(error);
    } finally {
      setIsLoading(false);
    }
  };

  function DeleteAccountSection() {
    const handleDeleteAccount = () => {
      setDeleteModalVisible(true);
    };

    const handleConfirmDelete = async () => {
      setIsLoading(true);

      try {
        await deleteAccount();
        signOut();
        setDeleteModalVisible(false);
      } catch (error) {
        setSubmitAccountDeleteError(error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleCancelDelete = () => {
      setDeleteModalVisible(false);
    };

    return (
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Delete Account</Text>
        <Button
          mode="contained"
          onPress={handleDeleteAccount}
          style={styles.button}
        >
          Delete
        </Button>
        <Modal visible={isDeleteModalVisible} animationType="slide" transparent>
          <View style={styles.modalContainer}>
            <HelperText type="error" visible={submitAccountDeleteError !== ''}>
              {submitAccountDeleteError}
            </HelperText>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>
                Are you sure you want to delete your account?
              </Text>
              <View style={styles.modalButtons}>
                <Button
                  mode="outlined"
                  onPress={handleCancelDelete}
                  style={styles.button}
                >
                  Cancel
                </Button>
                <Button
                  mode="contained"
                  onPress={handleConfirmDelete}
                  style={styles.button}
                >
                  Confirm
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior="padding"
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Reset Password</Text>
          <TextInput
            style={[styles.input, isLoading && styles.disabledInput]}
            label="Current Password"
            secureTextEntry
            value={currentPassword}
            onChangeText={setCurrentPassword}
            editable={!isLoading}
          />
          <TextInput
            style={[styles.input, isLoading && styles.disabledInput]}
            label="New Password"
            secureTextEntry
            value={newPassword}
            onChangeText={setNewPassword}
            editable={!isLoading}
            error={passwordError !== '' ? true : false}
          />
          <HelperText type="error" visible={passwordError !== ''}>
            {passwordError}
          </HelperText>
          <Button
            mode="contained"
            onPress={handleResetPasswordSubmit}
            disabled={isLoading}
            style={styles.button}
            loading={isLoading}
          >
            Submit
          </Button>
          {resetStatus === 'success' && (
            <Text style={styles.successText}>Password reset successful!</Text>
          )}
          {resetStatus === 'failure' && (
            <Text style={styles.errorText}>{`${submitPasswordError}`}</Text>
          )}
        </View>
        <View style={styles.divider} />
        <View style={styles.sectionContainer}>
          <DeleteAccountSection />
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  backButton: {
    fontSize: 18,
    marginRight: 8,
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  sectionContainer: {
    marginTop: 20,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    marginBottom: 12,
  },
  passwordError: {
    color: 'red',
    marginTop: 6,
    fontSize: 12,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 16,
    borderRadius: 8,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  divider: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  disabledInput: {
    backgroundColor: '#ddd',
  },
  successText: {
    color: 'green',
    marginTop: 10,
  },
  errorText: {
    color: 'red',
    marginTop: 10,
  },
  button: {
    marginTop: 10,
  },
});
