import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_BASE_URL, API_URL } from './config';
import { useNavigation } from '@react-navigation/native';

/**
 * Logs the user out, by removing items from Async storage.
 */
export const logout = async () => {
  try {
    await AsyncStorage.removeItem('token');
    await AsyncStorage.removeItem('user_info');
  } catch (error) {
    console.error('Failed to logout current user:', error);
  }
};

/**
 * Get the current auth token for the logged-in user.
 * @returns The user JWT token, as a string.
 */
export const getToken = async () => {
  const token = await AsyncStorage.getItem('token');
  return token;
};

/**
 * Get the user info for the current user.
 * @returns The user info object for the currently logged-in user.
 */
export const getUserInfo = async () => {
  const userInfo = await AsyncStorage.getItem('user_info');
  return JSON.parse(userInfo);
};

export const login = async (username, password) => {
  const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password,
    }),
  });

  if (response.status === 401) {
    throw { type: 'auth', message: 'Invalid username or password' };
  }

  if (!response.ok) {
    const errorMessage = await response.text();
    throw { type: 'server', message: `An error occurred: ${errorMessage}` };
  }

  const json_response = await response.json();
  const data = json_response.data;

  await AsyncStorage.setItem('token', data.jwt);
  await AsyncStorage.setItem('user_info', JSON.stringify(data.user));

  return data.jwt;
};

/**
 * Register a new user.
 *
 * @param userInfo - username, display_name, date_of_birth, password, email
 * @returns
 */
export const register = async (userInfo) => {
  let response;
  try {
    response = await fetch(`${API_BASE_URL}/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
  } catch (error) {
    throw { type: 'network', message: 'An unexpected network error occurred' };
  }

  if (!response.ok) {
    if (response.status === 422) {
      const json_response = await response.json();
      const messages = json_response?.error?.messages ?? {
        error: 'an unknown error occurred',
      };
      throw { type: json_response?.error?.code ?? 'invalid_input', messages };
    } else if (response.status === 403) {
      const json_response = await response.json();
      const messages = json_response?.error?.messages ?? {
        error: 'an unknown error occurred',
      };
      console.log('Content failed moderation: ', messages);
      throw { type: 'invalid_input', messages };
    } else {
      console.log('Register error: ', response);
      throw {
        type: 'server',
        messages: { error: 'An unexpected error occurred' },
      };
    }
  }

  // Save the token to AsyncStorage
  const json_response = await response.json();
  const data = json_response.data;
  await AsyncStorage.setItem('token', data.jwt);
  await AsyncStorage.setItem('user_info', JSON.stringify(data.user));

  return data.jwt;
};

export const sendPasswordResetEmail = async (email) => {
  const response = await fetch(`${API_BASE_URL}/api/auth/forgot_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    // TODO: Better error messages
    throw new Error(errorMessage);
  }

  const json_response = await response.json();
  console.log('Sent password reset email: ', json_response);
  return response;
};

export const resetPassword = async ({ token, password }) => {
  const response = await fetch(`${API_BASE_URL}/api/auth/reset_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
      password,
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    // TODO: Better error messages
    throw new Error(errorMessage);
  }

  const json_response = await response.json();
  console.log('User password reset: ', json_response);

  return response;
};

export const checkSession = async () => {
  try {
    const token = await getToken();

    const response = await fetch(`${API_URL}/check-session`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
