import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Switch } from 'react-native-paper';
import { loadUserProfile, updatePushNotificationsEnabled } from '../api/user';
import { getUserInfo } from '../api/auth';

export default function NotificationSettings({ navigation }) {
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserInfo(userInfo);
        const response = await loadUserProfile(userInfo.username);
        if (response?.data?.push_notifications_enabled === true) {
          setPushNotificationsEnabled(true);
        } else {
          setPushNotificationsEnabled(false);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function togglePushNotificationsEnabled(enabled) {
    updatePushNotificationsEnabled(userInfo.username, enabled)
      .then((data) => {
        if (data.push_notifications_enabled === true) {
          setPushNotificationsEnabled(true);
        } else {
          setPushNotificationsEnabled(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function ToggleButton() {
    const togglePushNotifications = () => {
      const newEnabledStatus = !pushNotificationsEnabled;
      togglePushNotificationsEnabled(newEnabledStatus);
    };

    return (
      <View style={styles.toggleContainer}>
        {loading ? (
          <ActivityIndicator size="small" color="#6200ee" />
        ) : (
          <>
            <Text style={styles.toggleLabel}>Push Notifications {pushNotificationsEnabled ? 'Enabled' : 'Disabled'}</Text>
            <Switch
              value={pushNotificationsEnabled}
              onValueChange={togglePushNotifications}
              color="#6200ee"
            />
          </>
        )}
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ToggleButton />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingBottom: 10,
  },
  backButton: {
    fontSize: 18,
    marginRight: 8,
  },
  headerTitle: {
    flex: 1,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  toggleLabel: {
    fontSize: 16,
    marginRight: 8,
  },
});
